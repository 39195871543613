<template>
  <header class="hk-header">
    <div class="hk-header__inner hk-desktop">
      <img
        class="hk-header__logo hk-desktop"
        src="~images/logo.svg"
        @click="goHome"
      />
      <hk-search v-model="search" />
      <hk-language v-model="language" />
      <hk-menu v-show="hasMenu" />
      <hk-user v-if="hasAccount" :identity=displayIdentity />
      <div v-if="title" class="hk-header__button">
        <el-button @click="goHome">{{ $t('tab.home') }}</el-button>
        <span>{{ $t(`footer.${title}`) }}</span>
      </div>
    </div>
    <div class="hk-header__inner hk-mobile">
      <hk-user v-if="hasAccount" :identity=displayIdentity />
      <div v-else class="hk-header__logo">
        <img src="~images/logo.svg" @click="goHome" />
      </div>
      <hk-language v-model="language" />
    </div>
    <slot />
    <div class="hk-header__mobile hk-mobile">
      <hk-search
        v-model="search"
        :class="{'hk-search_no-slot': !$slots.default}"
      />
      <hk-menu v-if="hasMenu" />
    </div>
  </header>
</template>

<script>
import Utils from 'common/utils'
import HkSearch from '@/components/search'
import HkLanguage from '@/components/language'
import HkMenu from '@/components/menu'
import HkUser from '@/components/user'
export default {
  name: 'HkHeader',
  components: {
    HkSearch,
    HkLanguage,
    HkMenu,
    HkUser
  },
  data () {
	  
    return {
      identity: {
        avator: this.$store.state.data.user.avatar,
        name: this.$store.state.data.user.username,
        demoImg : this.$store.state.data.dumpImg,
        
      },
      search: '',
      language: Utils.getLanguage(),
      loginStatus: false,
    }
  },
  beforeRouteUpdate (to, from, next) {
  // just use `this`
    this.avator = to.$store.state.data.user.avatar;
    this.name = to.$sotre.state.data.user.username
    next()
  },  
  mounted () {
    this.identity.avator = this.$store.state.data.user.avatar;
    this.identity.name = this.$store.state.data.user.username;
  },
  watch: {
    search (val) {
      //
      this.$route.name !== 'search' && this.$router.push({
        name: 'search'
      })
    },
    language (val) {
      Utils.setLanguage(val)
      this.$store.commit('changeLanguage', val)
      this.$i18n.locale = val
    },
    identity (val) {
      val.avator = this.$store.state.data.user.avatar;
      val.name = this.$store.state.data.user.username;
    }
  },
  methods: {
  },
  computed: {
    title () {
      return this.$route.meta.title
    },
    computeAvatar () {
      return this.identity.avator
    },
    hasAccount () {
      return this.$route.meta.account !== false
    },
    hasMenu () {
      return this.$route.meta.menu !== false
    },
 
    
    displayIdentity() {
      let self = this;
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/user/info?lang=';
///
      if (!self.loginStatus) {
        this.axios.get(apiCall,
          {
            headers: {
              'token': localStorage.getItem('SSID'),
            }
          }
        )
        .then(function (response) {
	        
          let reviewStatus = response.data.data;
          self.$store.commit('changeUserIdentity', reviewStatus);
          switch(reviewStatus.pass) {
            case 'SUC' :
              self.loginStatus = true;
			        self.identity.avator = self.$store.state.data.userInfo.avatar;
			        self.identity.name = self.$store.state.data.userInfo.username;
              
              break
            case 'FAIL' :
              self.loginStatus = false;
              break
            case 'WAIT' :
              self.loginStatus = false;
              break
            case 'UNSUBMIT' :
              self.loginStatus = false;
              break
          }
         
        })
        .catch(function (error) {
          console.log(error);
        })
      }
///
		      if (!self.loginStatus) {
		        self.identity.avator = self.$store.state.data.dumpImg;
		        self.identity.name = null;
		      } else {
		        self.identity.avator = self.$store.state.data.userInfo.avatar;
		        self.identity.name = self.$store.state.data.userInfo.username;
		      }  
      return this.identity
    }
  },
  methods: {
    goHome () {
      this.$store.state.config.popupAlert = true
      this.$router.push({
        name: 'home'
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.hk-header {
  .hk-header__inner {
    display: flex;
    align-items: center;

    .hk-header__logo {
      width: 235px;
      padding-top: 30px;
      cursor: pointer;
    }

    .hk-header__button {
      flex: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .el-button.el-button--default {
        margin-right: 30px;
        font-size: $subTitleSize;
        color: $subTitleColor;
        background-color: transparent;
        border: none;
      }

      > span {
        display: inline-block;
        font-size: $titleSize;
        line-height: 32px;
        text-align: right;
        color: $blockColor;
        word-break: break-word;
      }
    }
  }

  @include mobile {
    .hk-header__inner {
      margin: 35px;

      .hk-header__logo {
        flex: auto;
        padding-top: 0;

        img {
          width: 180px;
        }
      }
    }

    .hk-header__mobile {
      position: relative;
      height: 182px;
      margin-bottom: 30px;
    }
  }
}
</style>
