<template>
  <el-dropdown
    class="hk-language"
    trigger="click"
    @command="handleSelect"
  >
    <span class="hk-language__label">
      <p v-if="value === 'en'">
        <img src="~images/english.png" />English
      </p>
      <p v-else-if="value === 'zh'">
        <img src="~images/chinese.png" />繁體中文
      </p>
      <i class="el-icon-arrow-down el-icon--right" />
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="en">
        <img src="~images/english.png">
        <span>English</span>
      </el-dropdown-item>
      <el-dropdown-item command="zh">
        <img src="~images/chinese.png">
        <span>繁體中文</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'HkLanguage',
  model: {
    prop: 'value',
    event: 'update:value'
  },
  props: {
    value: {
      type: String,
      default: 'en'
    }
  },
  methods: {
    handleSelect (val) {
      this.$emit('update:value', val)
      location.reload();
    }
  }
}
</script>

<style lang="scss" scoped>
.hk-language::v-deep {
  margin-right: 30px;

  .hk-language__label {
    display: flex;
    align-items: center;
    font-size: $textSize;
    color: $subTitleColor;
    cursor: pointer;

    p {
      display: flex;
      align-items: center;
      width: 120px;
    }

    img {
      width: 30px;
      margin-right: 12px;
    }

    i {
      color: $subTitleColor;
    }
  }

  @include mobile {
    margin-right: 0;

    .hk-language__label {
      font-size: 26px;

      p {
        width: auto;
      }
    }
  }
}

.el-dropdown-menu__item {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: $descriptionSize;
  color: $textColor;

  img {
    width: 30px;
    margin-right: 12px;
  }

  @include mobile {
    font-size: 30px;
    margin: 10px 0;

    img {
      width: 40px;
    }
  }
}
</style>
