<template>
  <div class="hk-sidebar">
    <ul class="hk-sidebar__menu hk-desktop">
        <li class="hk-sidebar__button" @click="handleClick('cart')">
          <el-badge :value="cartTotal" :hidden="!cartTotal">
            <i class="iconfont icon-cart" />
          </el-badge>
        </li>
      <li class="hk-sidebar__button" @click="handleClick('favorite')">
        <i class="iconfont icon-like" />
      </li>
      <li class="hk-sidebar__button" @click="handleClick('aboutContact')">
        <i class="iconfont icon-phone" />
      </li>
    </ul>
    <div class="hk-sidebar__line hk-desktop" />
    <ul class="hk-sidebar__tab hk-mobile">
      <li
        v-for="option in options"
        :key="option.to"
        class="hk-sidebar__button"
        :class="{'hk-sidebar__button_active': current === option.to}"
        @click="handleClick(option.to)"
      >
        <i class="iconfont" :class="option.icon" />
        <span class="hk-sidebar__label">
          {{ option.label }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HkSidebar',
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },
    cartTotal: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      cartNum: 0,
      showAlertDialog: false,
      alertMessage: ''
    }
  },
  computed: {
    current () {
      return this.$route.name
    },
  },
  methods: {
    handleClick (name) {
      if ( name == 'cart' || name == 'favorite') {
        this.getStatus(name);
      } else {
        this.current !== name && this.$router.push({ name });
      }
    },
    getStatus (name) {
      let self = this;
      let header = {
        headers: {
          'token': localStorage.getItem('SSID'),
        }
      }
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/user/info?lang='
      this.axios.get(apiCall, header)
      .then(function (response) {
        if ( response.data.data.userType == 'GUEST' ){
          self.$store.commit('openLoginDialog');
        } else {
        let reviewStatus = response.data.data;
          switch(reviewStatus.pass) {
            case 'SUC' :
              self.current !== name && self.$router.push({ name })
              break
            case 'WAIT' :
            case 'FAIL' :
            case 'UNSUBMIT' :
              self.$store.commit('openReviewDialog');
              break
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      })
    },  }
}
</script>

<style lang="scss" scoped>
.hk-sidebar {
  position: absolute;
  left: 20px;
  top: 220px;
  width: 45px;
  z-index: $siderbarIndex;

  .hk-sidebar__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .hk-sidebar__button::v-deep {
      margin: 20px 0;
      color: $themeColor;
      cursor: pointer;

      .iconfont {
        font-size: 36px;
      }

      .el-badge__content {
        height: 30px;
        padding: 0 14px;
        font-weight: bold;
        line-height: 30px;
        font-size: 22px;
        border: none;
        border-radius: 20px;
        background-color: #ff4c15;
      }
    }
  }

  .hk-sidebar__line {
    position: relative;
    width: 100%;
    height: 570px;
    margin-top: 20px;
    background-color: $themeColor;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 7px;
      display: block;
      width: 25px;
      height: 25px;
      border: 3px solid $mainColor;
      border-radius: 50%;
    }

    &::before {
      top: 20px;
    }

    &::after {
      bottom: 20px;
    }
  }

  .hk-sidebar__tab {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: $footerHeight;
    background-color: $subMainColor;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.03);

    .hk-sidebar__button {
      flex: 1;
      color: $subTitleColor;
      cursor: pointer;

      .hk-sidebar__label {
        display: block;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
      }

      .iconfont {
        display: block;
        width: 48px;
        height: 48px;
        line-height: 48px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 2px;
        font-size: 36px;
        color: $subTitleColor;
        background-size: cover;
        text-align: center;

      }

      &.hk-sidebar__button_active {
        color: $themeColor;

        .iconfont {
          color: $themeColor;
        }
      }
    }
  }

  @include mobile {
    position: fixed;
    top: initial;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

@media only screen and (-webkit-device-pixel-ratio: 3) {
  .hk-sidebar {
    background-color: $subMainColor;

    .hk-sidebar__tab {
      position: relative;
      bottom: constant(safe-area-inset-bottom);
      bottom: env(safe-area-inset-bottom);
    }
  }
}
</style>
