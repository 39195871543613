export default {
  base: '/',
  routes: [{
    path: 'login',
    name: 'login',
    component: () => import('@/pages/login'),
    meta: {
      auth: false,
      account: false,
      menu: false,
      sidebar: false
    }
  }, {
    path: 'regist',
    name: 'regist',
    component: () => import('@/pages/login/regist'),
    meta: {
      auth: false,
      account: false,
      menu: false,
      sidebar: false,
      header: false,
      footer: false,
    }
  }, {
    path: 'regist/preview',
    name: 'registPreview',
    component: () => import('@/pages/login/preview'),
    meta: {
      auth: false,
      account: false,
      menu: false,
      sidebar: false,
      header: false,
      footer: false,
    }
  }]
}
