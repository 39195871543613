<template>
  <el-menu
    ref="menu"
    mode="horizontal"
    class="hk-menu"
    :class="{'hk-menu_fixed': fixed}"
    :default-active="activeIndex"
  >
    <!---
    <el-menu-item index="0">{{ $t('menu.net') }}</el-menu-item>
    <el-menu-item index="1">{{ $t('menu.seal') }}</el-menu-item>
    --->
    <el-menu-item v-for="category in computeCategoryList" :key="category.category_id">
      <p :index="category.category_id" v-on:click="goCategory(category.category_id)">{{ category.category_name }}</p>
    </el-menu-item>
    <el-menu-item index="99" @click="goDiscount(99)">{{ $t('menu.discount') }}</el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: 'HkMenu',
  data () {
    this.getCategoryList();
    return {
      el: null,
      pages: [
        'home', // home/id=1
        'seal',
        'discount'
      ],
      fixed: false,
      offsetTop: 127,
      categoryList: [],
    }
  },
  computed: {
    activeIndex () {
      return this.pages.indexOf(this.$route.name).toString()
    },
    computeCategoryList: function() {
      return this.categoryList
    }
  },
  mounted () {
    this.el = this.$refs.menu
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleMenu (index) {
      const name = this.pages[index]
      this.$route.name !== name && this.$router.push({ name })
    },
    handleScroll () {
      if (!this.el) {
        return
      }
      const scrollTop = window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (!this.fixed) {
        const top = document.querySelector('.hk-header__mobile').offsetTop
        this.offsetTop = top + this.el.$el.offsetTop
      }
      this.fixed = scrollTop > this.offsetTop
    },
    getCategoryList () {
      let self = this;
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/product/categorys?lang=' + this.$store.state.config.language;
      this.axios.get(apiCall)
      .then(function (response) {
        self.categoryList = response.data.data
      })
      .catch(function (error) {
        console.log(error);
      })
    },
    goCategory (category, index) {
      this.$store.state.config.popupAlert = false
      this.$router.push({
        name: 'home',
        query: { category }
      })
    },
    goDiscount (index) {
      this.$router.push('discount')
    }
  }
}
</script>

<style lang="scss" scoped>
.hk-menu.el-menu::v-deep {
  flex: auto;
  background: none;
  border: none;

  .el-menu-item,
  .el-menu-item:hover,
  .el-menu-item:focus {
    min-width: 140px;
    margin-right: 15px;
    padding: 0;
    font-size: $subTitleSize;
    text-align: center;
    color: $blockColor;
    background: none;
    border: none;

    &.is-active {
      font-weight: bold;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @include mobile {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 30px;
    background-color: $mainColor;
    border-bottom: $splitBorder;
    z-index: 2;

    &.hk-menu_fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: auto;
      height: 80px;

      .el-menu-item {
        line-height: 80px;
      }
    }

    .el-menu-item,
    .el-menu-item:hover,
    .el-menu-item:focus {
      min-width: auto;
      margin-right: 60px;
      font-size: 28px;
      text-align: left;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
