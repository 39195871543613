/*
 * @Author: ullone l
 * @Date: 2022-04-09 13:18:22
 * @LastEditors: ullone l
 * @LastEditTime: 2022-05-13 22:31:24
 * @FilePath: \hkstsnew\hksts\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import Utils from '../common/utils'

Vue.use(Vuex)

const state = {}

export default new Vuex.Store({
  state: {
    data: {
      user: {
        avatar: 'http://api.hksts.com.hk/uploads/default.png',
        // name: '',
        username: '',
        companyCn: '',
        companyEn: '',
        address: '',
        delivery_address_id: '',
        delivery_address: '',
        mail_address_id: '',
        mail_address: '',
        mailAddress: '',
        mailAddressDetail: '',
        number: '',
        type: [],
        typeOther: '',
        companyType: '',
        companyTypeOther: '',
        contactsCn: '',
        contactsEn: '',
        receiveContactNameZh: '',
        receiveContactNameEn: '',
        receiveContactMobile: '',
        emial: '',
        phone: '',
        agree: [],
        brImagePath: '',
        ciImagePath: ''
      },
      brImagePath: '',
      ciImagePath: '',
      userInfo: {},
      dumpImg: 'https://api.hksts.com.hk/uploads/default.png'
    },
    config: {
      userInfo: {},
      mailingInfo: {},
      search: {},
      id: Number,
      account: {},
      cartNum: 0,
      invoiceNum: '',
      language: Utils.getLanguage(),
      apiRoot: 'https://api.hksts.com.hk',
      // apiRoot: 'http://hksts.dev.showmuse.so',
      stripeBackUrl: 'http://www.hksts.com.hk/#/payment?sn=',
      minDate: '',
      maxDate: '',
      pickupSlot: '',
      loginStatus: false,
      loginAlert: false,
      reviewAlert: false,
      popupAlert: true
    },
    productAddress: {}
  },
  getters,
  mutations,
  actions
})
