export default {
  register(state, registerData) {
    state.data.user = registerData
  },
  prefillEmail(state, email) {
    state.data.user.emial = email
  },
  brUpload(state, imagePath) {
    state.data.user.brImagePath = imagePath
  },
  delBr(state) {
    state.data.user.brImagePath = ''
  },
  ciUpload(state, imagePath) {
    state.data.user.ciImagePath = imagePath
  },
  delCi(state) {
    state.data.user.ciImagePath = ''
  },
  changeLanguage(state, lang) {
    state.config.language = lang
  },
  changeUserIdentity(state, identity) {
    state.config.userInfo = identity

    state.data.userInfo = identity
  },
  getUserId(state, userConfig) {
    state.config.account = userConfig
  },
  changeMailingInfo(state, info) {
    state.config.mailInfo = info
  },
  initUser(state) {
    state.config.userInfo = {
      avatar: state.config.apiRoot + '/uploads/default.png',
      username: 'Visitor'
    }
    localStorage.removeItem('SSID')
    localStorage.removeItem('HKSTS__userId')
    localStorage.removeItem('HKSTS__userNum')
    state.config.cartNum = 0
  },
  getSearch(state, outcome) {
    state.config.search = outcome
  },
  getProductId(state, id) {
    state.config.productId = id
  },
  updateCartNum(state, num) {
    state.config.cartNum = num
  },
  saveProductAddress(state, productAddress) {
    state.productAddress = productAddress
  },
  saveInvoiceNum(state, invoiceNumber) {
    state.config.invoiceNum = invoiceNumber
  },
  savePickupDate(state, date) {
    state.config.minDate = date[0]
    state.config.maxDate = date[1]
  },
  savePickupSlot(state, date) {
    state.config.pickupSlot = date
  },
  saveLoginStatus(state, status) {
    state.config.saveLoginStatus = status
  },
  openLoginDialog(state) {
    state.config.loginAlert = true
  },
  closeLoginDialog(state) {
    state.config.loginAlert = false
  },
  openReviewDialog(state) {
    state.config.reviewAlert = true
  },
  closeReviewDialog(state) {
    state.config.reviewAlert = false
  }
}
