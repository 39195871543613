import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

export default {
  common: {
    search: '搜索',
    detail: '詳 情',
    purchase: '加到購物車',
    favorite: '收 藏',
    carton: '箱',
    date: '{0} 到 {1}',
    stock: '庫存 {0} 箱',
    color: '顏色',
    size: '尺寸',
    weight: '重量',
    packing: '包裝',
    unit: '{0}個 / 箱',
    avgPrice: ' 平均價錢',
    outOfStock: '缺貨',
    company: '公司名稱',
    area: '所在地區',
    areaDesc: '請選擇所在區',
    deliveryAddress: '詳細地址',
    deliveryAddressDesc: '請填寫詳細地址',
    contact: '收貨人姓名',
    contactDesc: '請使用真實姓名',
    phone: '電話',
    email: '電子郵箱',
    fillIn: '請填寫',
    edit: '修改',
    delete: '刪除',
    next: '下一步',
    prev: '上一步',
    finish: '完 成',
    confirm: '確 認',
    confirmInvoice: '下一步',
    cancel: '取 消',
    success: '已發送',
    back: '返回首頁',
    close: '關閉',
    payment: '付款已完成',
    giftPrice: '贈品價值',
    paymentStatus: {
      success: '你的訂單（{0}）支付成功。',
      failed: '你的訂單（{0}）支付失敗。',
      offline:
        '你的訂單（{0}）線下支付正在審批中。我們已發送訂單確認郵件去你的注冊電子郵箱。請查收。',
      offlineNoEmail: '你的訂單（{0}）線下支付正在審批中。',
      unpaid: '你的訂單（{0}）尚未支付。'
    },
    underReview: '我們正在審批你的帳戶。你暫時無法使用此功能。',
    noData: '暫無數據',
    commandConfirm: '我們已收到指示',
    selectAll: '全選',
    unselectAll: '取消全選'
  },
  product: {
    net: {
      type: '智全網系列',
      'mdp/q7': {
        info: {
          1: 'Smartsec智全系統是一套獲得民航處批准的運輸保安措施，旨在保護由管制空運貨物安檢設施運送至機場貨運站的已檢查貨物，以防貨物遭受非法幹擾。',
          2: '這套運輸保安措施需要使用智全網保護已檢查的貨物，並採用可防拆換的保安措施 (有編號的封條)，以便確認托運貨物是否完整無損。'
        },
        desc: {
          1: '尺寸: 125" x 96" x 118" H',
          2: '智全網顏色為黑色，束網帶為藍色',
          3: '只適用 高度為64"以上板 使用，不適用於"凸肚"板、高度為64"或以下板 及 "20尺"板'
        },
        discount: '{0}：買 {1} 送 {2}（均價 HK$ {3}）',
        groupBuying: {
          1: '滿{0}箱，團購價 HK$ {1}'
        }
      },
      '20ft': {
        desc: {
          1: '尺寸: 238" x 96" x 118" H',
          2: '智全網顏色為黑色，束網帶為紅色',
          3: '只適用 "20尺"板 使用，不適用於其他板型'
        }
      },
      w2: {
        desc: {
          1: '尺寸: 182" x 96" x 64" H',
          2: '智全網顏色為黑色，束網帶為綠色',
          3: '適用於所有 "凸肚"板 及 高度為64"或以下板使用，不適用於 高度為64"或以上板 及 "20尺"板'
        }
      },
      a2: {
        desc: {
          1: '尺寸: 125" x 96" x 64" H',
          2: '智全網顏色為黑色，束網帶為橙色',
          3: '只適用 高度為64"或以下板 使用，不適用於 "凸肚"板、高度為64"或以上板 及 "20尺"板'
        }
      }
    },
    seal: {
      type: '鎖網封條系列',
      '19pcs/set': {
        desc: {
          1: '顏色為白色',
          2: '印有特別封條號碼及二維碼',
          3: '適用於MDP/Q7、W2及A2網型, 不適用於20尺網型'
        }
      },
      '29pcs/set': {
        desc: {
          1: '顏色為橙色',
          2: '印有特別封條號碼及二維碼',
          3: '只適用於20尺網型,  不適用於其他網型'
        }
      }
    },
    details: {
      discount: '{0}：買 {1} 送 {2}（均價 HK$ {3}）',
      groupBuying: {
        1: '滿{0}箱，團購價 HK$ {1}'
      }
    }
  },
  search: {
    title: '搜索結果',
    results: {
      1: {
        title: '<b>智全網</b>系列 mdp/q7',
        desc:
          'Smartsec智全系統是一套獲得民航處批准的運輸保安措施，旨在保護由管制空運貨物安檢設施運送至機場貨運站的已檢查貨物，以防貨物遭受非法幹擾。這套運輸保安措施需要使用智全網保護已檢查的貨物，並採用可防拆換的保安措施 (有編號的封條)，以便確認托運貨物是否完整無損。'
      },
      2: {
        title: '<b>智全網</b>系列 20ft',
        desc:
          '尺寸: 238" x 96" x 118" H <b>智全網</b>顏色為黑色，束網帶為紅色。只適用 "20尺"板 使用，不適用於其他板型'
      },
      3: {
        title: '<b>智全網</b>系列 w2',
        desc: '尺寸: 182" x 96" x 64" H <b>智全網</b>顏色為黑色，束網帶為綠色'
      },
      4: {
        title: '<b>智全網</b>系列 a2',
        desc: '尺寸: 125" x 96" x 64" H <b>智全網</b>顏色為黑色，束網帶為橙色'
      }
    }
  },
  menu: {
    net: '智全網',
    seal: '鎖網封條',
    discount: '團購優惠'
  },
  header: {
    account: '我的賬戶',
    logout: '退出登錄'
  },
  login: {
    login: '用戶登入/註冊',
    homepageLogin: '用戶登入',
    register: '註 冊',
    title: '<b>Hello</b>, 請登入',
    notRegist: '還不是會員？',
    registNow: '請馬上註冊',
    registEmail: '請輸入電郵',
    sms: '請輸入驗證碼',
    sendSms: '發送驗證碼',
    username: '請輸入電郵或登記名稱',
    password: '請輸入啓動電郵裏提供的密碼',
    remember: '記住密碼',
    forgot: '忘記密碼？',
    registeredMember: '註册會員',
    loginId: '商戶登記名稱',
    loginIdDesc: '請輸入商戶名稱',
    companyNameCn: '公司名字(中)',
    companyNameCnDesc: '請輸入中文公司名',
    companyNameEn: '公司名字(英)',
    companyNameEnDesc: '請輸入英文公司名',
    deliveryAddressDesc: '請輸入完整收貨地址',
    mailingAddress: '郵寄地址',
    mailingAddressDesc: '請輸入詳細地址',
    businessRegistrationNo: '商業登記號碼',
    businessRegistrationNoDesc: '請輸入11位號碼',
    businessType: '行業類型',
    freightForwarders: '貨運代理人',
    trucker: '運輸公司',
    racsf: '管制空運貨物安檢設施',
    ra: '管制代理人',
    others: '其他',
    companyType: '公司類別',
    soleProprietorship: '無限公司',
    partnership: '合夥',
    limitedCompany: '有限公司',
    username: '暱稱',
    usernameDesc: '請輸入暱稱',
    contactPersonCn: '聯絡人(中文名)',
    contactPersonCnDesc: '請輸入聯絡人中文名',
    contactPersonEn: '聯絡人(英文名)',
    contactPersonEnDesc: '請輸入聯絡人英文名',
    receiveContactNameZh: '收貨聯絡人(中文名)',
    receiveContactNameZhDesc: '請輸入收貨聯絡人中文名',
    receiveContactNameEn: '收貨聯絡人(英文名)',
    receiveContactNameEnDesc: '請輸入收貨聯絡人英文名',
    nick: '暱稱',
    avatar: '頭像',
    email: '電郵',
    emailDesc: '請輸入電郵地址',
    phone: '電話',
    receiveContactMobile: '收貨聯絡人電話',
    phoneDesc: '請輸入電話號碼',
    receiveContactMobileDesc: '請輸入收貨聯絡人電話號碼',
    pleaseUpload: '請上傳',
    BRCopy: '商業登記副本',
    CICopy: '公司註冊證明副本',
    clickToUpload: '點擊上傳文件',
    agree: '同意 ',
    termsAndConditions: '使用條款',
    and: ' 及 ',
    privacyPolicy: '私隱政策',
    preview: '預 覽',
    submit: '提 交',
    successfulSubmitted: '提交成功',
    successfulEdit: '個人資料修改成功',
    success: {
      1: '請留意註册電子郵箱。',
      2: '審批成功後，我們會發啓動郵件給你。',
      3: '我們一般在3工作天內發郵件，如沒有收到，請致電 {0}.'
    },
    fail: {
      1: '你的註册沒通過審批。',
      2: '如有任何問題，請致電 {0}.'
    },
    mailRequired: '必須輸入用戶',
    mailType: '請輸入正確電郵格式',
    wait: '你的註册還在審批中，請稍等',
    uploadImageSuccess: '圖片上載成功。',
    uploadSuccess: '上載成功。',
    uploadFail: '上載失敗',
    uploadImageMissing: '請上載銀行單',
    uploadImageFail: '圖片上載失敗。',
    uploadFormatCheck: '上傳文件格式錯誤。你只能上傳JPEG / JPG / PNG格式圖片。',
    uploadFormatCheckWPDF:
      '上傳文件格式錯誤。你只能上傳JPEG / JPG / PNG / PDF格式文件。',
    uploadSizeCheck: '上傳文件大小不能超過 3MB！',
    ciOnly: '有限公司適用',
    alertMessage: {
      sendSMS: {
        success: '驗證碼已發送',
        fail: '驗證碼發送失敗'
      },
      getPassword: {
        success: '請查看郵件，並以啓動郵件裏的密碼登入網站',
        emailExist: '電子郵箱已被使用。請用另一個電郵再嘗試。',
        invalidCode: '驗證碼無效',
        fail: '驗證碼錯誤',
        expiredCode: '驗證碼已過期，請重新驗證。'
      },
      resetEmail: {
        success: '更改電郵成功，請用新電郵重新登入',
        emailExist: '電子郵箱已被使用。請用另一個電郵再嘗試。',
        invalidCode: '驗證碼無效',
        fail: '驗證碼錯誤',
        expiredCode: '驗證碼已過期，請重新驗證。'
      },
      resetPassword: {
        success: '請查看郵件，並以重設郵件裏的密碼登入網站',
        invalidCode: '驗證碼無效',
        fail: '驗證碼錯誤',
        notRegister: '此電子郵箱未註冊',
        expiredCode: '驗證碼已過期，請重新驗證。'
      },
      login: {
        wrongPassword: '密碼錯誤',
        reviewFail: '你的註冊沒通過審批。\n如有任何問題，請致電63126760。'
      }
    },
    loginFirst: '請先登入',
    index: '返回首頁'
  },
  forms: {
    required: '必須填寫資料',
    phoneTypeError: '電話格式不正確',
    emailTypeError: '電郵格式不正確',
    emailOrPhoneTypeError: '電郵或電話格式不正確',
    brTypeError: '商業登記號碼格式不正確',
    brTypeMissingError: '請上載商業登記副本',
    missingData: '必須填寫資料不能為空白',
    tncMustTrue: '請確定同意條款及私隱',
    minChar: '最少為{0}位',
    passwordNotMatch: '兩次密碼不相符',
    oldPasswordWrong: '舊密碼錯誤',
    passwordChangeSuccess: '修改密碼成功，請重新登入。',
    eightChar: '必須為8位數字',
    elevenChar: '必須為11位數字',
    mustBeNumber: '必須為數字'
  },
  home: {
    desktop: '歡迎購買<br><b>HKSTS “Smartsec</b> 智全系統<b>”</b>產品',
    mobile: '歡迎購買 <b>HKSTS</b><br><b>“Smartsec</b> 智全系統<b>”</b>'
  },
  account: {
    purchaseHistory: '購買紀錄',
    rebateAmountRecord: '回贈金額紀錄',
    addressManagement: '地址管理',
    changePassword: '修改密碼',
    informationChecking: '查看資料',
    date: '日期',
    invoiceDate: '下單日期',
    receiptDate: '收貨日期',
    invoiceNo: '單號',
    invoiceNumber: '訂單號',
    status: '狀態',
    amount: '總計',
    details: '詳情',
    view: '查看',
    rebateEarned: '獲取金額',
    rebateUsed: '已使用金額',
    balance: '餘額',
    prev: '上一頁',
    next: '下一頁',
    pending: '待付款',
    delivering: '遞送中',
    pickUp: '自取',
    finished: '已完成',
    paymentDesc: '我們還沒確認你的付款，請確認已經通過以下一個方式付款',
    phoneDesc: '如已經付款，請與HKSTS聯絡',
    deliveryDate: '送貨日期',
    deliveryAddress: '收貨地址',
    pickUpDate: '自取注意',
    selectPickUpDate: '請選擇自取時間段',
    addressDesc: '已保存收貨地址 (共 {0} 條)',
    contact: '收貨人',
    receiptAddress: '收貨地址',
    phone: '聯系電話',
    default: '常用',
    setDefault: '設爲常用',
    oldPassword: '舊密碼',
    oldPasswordDesc: '請輸入舊密碼',
    newPassword: '新密碼',
    newPasswordDesc: '請輸入新密碼',
    newPasswordAgain: '再次輸入新密碼',
    newPasswordAgainDesc: '請再次輸入新密碼',
    dialog: '我們已發送一封啓動郵件去你的新郵件地址，請查收。',
    failedPayment: '付款失敗。請與我們職員聯絡。',
    emailConfirm: '確認更改登入電郵？',
    cannotDeleteRegAddress: '注冊地址無法刪除',
    deleteSuccess: '成功刪除地址',
    delImg: '刪除',
    clickToView: '點擊查看',
    invoice: '訂單發票',
    receipt: '訂單收據'
  },
  detail: {
    groupByDesc: '購買任何產品（可混合搭配）滿{0}箱或以上，此產品團購價',
    discount: '優惠購',
    groupBuying: '團 購',
    quantity: '數量',
    contour: '建議的板類型',
    animation: '動畫視頻',
    guide: '用戶手冊',
    outOfStock: '當産品到達的時候，我們會電郵通知你',
    stockAmount: '貨物數量',
    stockAmountDesc: '請輸入貨物數量',
    addSuccess: '添加成功',
    addAlready: '已經收藏商品'
  },
  discount: {
    desc: '團購産品和數量',
    price: '團購價錢',
    originalPrice: '原價',
    quantity: '數量(箱)',
    title: '團購優惠',
    newDesc: '購買任何產品（可混合搭配）滿{0}箱或以上',
    description: {
      smartsec_net: '智全網 {0}箱或以上',
      smartsec_net_seal: '智全網 {0}箱或以上 + 鎖網封條 {1}箱或以上'
    },
    description_general: '{0}{1}箱或以上',
    description_general_details: '{0}，團購價 HK$ {1}',
    type: {
      1: {
        title: '團購優惠一',
        quantity: '智全網 {0}箱或以上 + 鎖網封條 {1}箱或以上'
      },
      2: {
        title: '團購優惠二',
        quantity: '智全網 {0}箱或以上'
      },
      3: {
        title: '團購優惠三',
        quantity: '智全網 {0}箱或以上'
      }
    }
  },
  cart: {
    productConfirm: '産品確定',
    address: '送貨地址',
    payment: '確認付款',
    product: '産品',
    productName: '産品名字',
    price: '價格',
    quantity: '數量',
    subtotal: '小計',
    expensesSubtotal: '費用小計',
    total: '費用總計',
    chosen: '已選擇',
    addAddress: '添加地址',
    saveAddress: '保存新地址',
    fillQuantity: '請填上數量',
    all: '餘下貨品',
    allProduct: '所有餘下貨品',
    partialProduct: '部分貨品',
    selectProduct: '選擇貨品',
    selectFreeProduct: '贈送産品',
    freeProduct: '贈送産品',
    rebate: '使用回贈金額',
    deliveryFee: '運輸費',
    sameDay: '即日送貨',
    normalDelivery: '標準送貨',
    sameDayDelivery: '即日送貨',
    urgentDelivery: '緊急送貨',
    pickUp: '自取',
    sameDayDesc:
      '是指在下午1:00前完成訂貨及付款程序，便能安排即日送貨，附加費為${0}',
    urgentDesc: '是指當訂貨及完成付款程式後，會於4小時內安排送貨，附加費為${0}',
    pickUpDesc: '請選擇自取時間段',
    selectDate: '選擇日期',
    selectPeriod: '選擇時期段',
    paymentAmount: '支付金額',
    choosePayment: '請選擇付款形式',
    onlinePayment: '網上直接付款',
    offlinePayment: '綫下付款',
    onlinePaymentDesc: {
      1: '由 Stripe 網上付款系統處理 Visa 及 Mastercard 信用卡付款'
    },
    methods: {
      1: '方法一',
      2: '方法二'
    },
    dialog: {
      1: '我們已發送訂單確郵件去你註册電子郵箱，請查收。',
      2: '如沒有收到郵件，請查閱“垃圾郵件箱”。',
      3: '再次發送郵件',
      4: '已重新發送'
    },
    quantityAlert: ' 贈品數量不能超過{0}件',
    priceAlert: ' 贈品價值不能超過${0}',
    maxGiftQuantity: '贈品數量上限：{0}件 | 已選贈品數量：{1}',
    maxGiftPrice: '贈品數量價值上限：${0} | 已選贈品總值：${1}',
    noProduct: '請先選擇商品',
    unselectProduct: '請選擇商品數量',
    missingOfflinePayType: '請先選擇線下付款方式',
    noGift: '請選擇贈送產品及輸入產品數量',
    uncompleteAlertMessage: '訂單創建尚未完成，請選擇付款方式。',
  },
  favorite: {
    title: '我的收藏'
  },
  tab: {
    home: '首頁',
    favorite: '收藏',
    cart: '購物車',
    account: '我的'
  },
  footer: {
    company: '公司介紹',
    contact: '聯繫我們',
    faqs: '常見問題',
    conditions: '使用條款',
    conditions2: '使用條款',
    privacy: '私隱政策',
    delivery: '送貨及自提安排',
    delivery2: '送貨及自提安排',
    exchange: '換貨政策',
    payment: '付款方式',
    payment2: '付款方式'
  },
  about: {
    company: {
      title: '公司簡介',
      eg1: {
        content1:
          '香港安全運輸系統有限公司，HKSTS，成立於2019年，是為香港空運運輸業，提供由民航處核准的安全運輸系統。',
        content2:
          '根據國際民航組織 "ICAO" 發佈最新的航空政策，凡未被本地民航處核准的已知發貨人，所有運送的空運貨物，需要於 2021年6月30日之前，實施100%貨物安全檢查。為確保貨物由安檢貨倉運送到貨運站期間，貨物沒有被可疑人物接觸過，HKSTS 特意設計一套安全運輸系統 “Smartsec 智全系統”供業界使用。',
        content3:
          'Smartsec 的特點，除了於智全網上加上束網帶，令貨物包裹得更完善之外，更以電腦取代目測核對鎖網封條，令收貨的安全度大大提高。智全網適用於多種飛機板型號，自動化資料處理更能提高準確性及減省資料輸入的工作量。安全運輸系統亦已申請專利，業界可以安心使用。透過簡單快捷的Smartsec，將香港空運界安全運輸提升至國際標準，提升運輸業競爭力。'
      }
    },
    contact: {
      1: '如您對本公司網頁或網上訂單有任何查詢或對我們的網上商店有任何意見，請與我們聯絡，我們會盡快回覆您的查詢。',
      2: '電郵'
    },
    faqs: {
      title: '常見問題',
      tips:
        '請注意，以下常見問題只用作指南及協助用途，不具有合約約束力，並不會更改本網站的條款及條件。',
      eg1: {
        title: '1. 如何聯繫我們？',
        content1:
          '我們很樂意為您服務，如果您對我們的貨品有任何建議，歡迎聯絡我們的客戶服務團隊。',
        content2: '電郵: '
      },
      eg2: {
        title: '2. 如何開設新帳戶？',
        content1:
          '到｢登入/登記」頁面，按指示填寫所有必填項目及上載文件副本，並提交申請。我們會於3個工作天內審批你的申請，如沒有資料缺漏，我們會將新帳戶資料以電郵通知你。'
      },
      eg3: {
        title: '3. 如何更改送貨地址及其他資料？',
        content1:
          '請登入本公司網店並到｢我的帳戶」更改您的帳戶資料。請注意，有部分資料不能改動。'
      },
      eg4: {
        title: '4. 如何進行網上購物？',
        content1:
          '4.1 揀選您需要的貨品，並添加至購物車。完成選購後，點選頁面右上角的「購物車」進行結帳。',
        content2: '4.2 填寫送貨及其他資料，另如有需要，請選擇是否緊急訂單。',
        content3: '4.3 確認金額後，選擇付款方式，再按指示完成付款程序。',
        content4:
          '4.4 付款被確認後，我們會將付款確認信以電郵通知客戶 及 通知有關送貨的資料詳情。',
        content5: '有關付款詳情，請參閱',
        content6: '付款方式',
        content7: '。'
      },
      eg5: {
        title: '5. 你們接受甚麼付款方式？',
        content1: '我們接受以下方式付款：',
        content2: '(i) 轉數快 或 銀行入數/轉帳 - 銀行櫃台、自動 櫃員機、網上理財',
        content3: '(ii) 由 Stripe 網上付款系統處理之Visa及 Mastercard 信用卡付款',
        content4:
          '',
        content5: '請留意，本網店不接受以現金方式付款。',
        content6: '有關付款詳情，請參閱',
        content7: '付款方式',
        content8: '。'
      },
      eg6: {
        title: '6. 有甚麼保安措施確保我的交易安全並有效？',
        content1:
          '所有信用卡持卡人均須獲得信用卡公司的授權。如果信用卡公司以任何理由拒絕授權付款，我們將無法接受您的訂單。'
      },
      eg7: {
        title: '7. 可以使用其他貨幣付款嗎？',
        content1: 'HKSTS的產品均以港幣定價，故只接受港元付款。'
      },
      eg8: {
        title: '8. 我可以透過電話提交訂單嗎？',
        content1: '我們不接受透過電話提交訂單。請到我們的網店選購商品。'
      },
      eg9: {
        title: '9. 我在提交訂單時遇到問題。',
        content1: '請嘗試清除瀏覽器上的cookie和緩存，然後重新登入您的帳戶。',
        content2:
          '如果仍有其他問題，請注意您所收到的錯誤訊息/提示，並聯繫我們的客戶服務團隊。我們很樂意接受您的訂單並幫助您解決問題。 '
      },
      eg10: {
        title: '10. 我的訂單內的運輸費用會怎樣計算？',
        content1: '運輸費用計算包括兩部分：',
        content2:
          '第一部分為基本運輸費用(已包括隧道費/路費)，這項目會在賬單上顯示，並在客戶支付貨品賬單時一併支付。',
        content3:
          '第二部分為額外運輸費用，這會按實際需要而產生，以每次運送計算，實報實銷方式收取，由買方直接以現金支付送貨司機。這部分將不會在賬單上顯示。額外運輸費用包括但不限於入閘費、登記費、停車費、超時等候費等。',
        content4: '有關運費及其他可能出現的費用安排，請參閱',
        content5: '送貨安排',
        content6: '。 '
      },
      eg11: {
        title: '11. 我可否自取貨品？',
        content1: '可以的，有關自提詳情及程序，請參閱',
        content2: '自提安排',
        content3: '。 '
      },
      eg12: {
        title: '12. 提交訂單後，我會收通知嗎？',
        content1:
          '是的。在您提交訂單後，我們會發送電郵給您，電郵內會包括帳單副本、訂單及付款資料。當您完成付款後，您將會再收到電郵通知已完成付款及有關送貨安排。'
      },
      eg13: {
        title: '13. 當我的訂單已準備好，我會收到通知嗎？',
        content1:
          '是的。當您完成付款後，您將會再收到電郵通知已完成付款及有關送貨/提貨安排。'
      },
      eg14: {
        title: '14. 可否為我的網上訂單作更改或取消？',
        content1: '訂單一經確認付款後將無法取消或更改。'
      },
      eg15: {
        title: '15. 如何辦理貨品換貨？',
        content1: '換貨條件',
        content2: '您將更換之貨品必須符合以下條件，否則，恕不接受換貨申請：',
        content3: '1. 貨品須於簽收後7天內提出更換。',
        content4:
          '2. 未使用前已發現貨品有任何非人為因素引致的破損、品質問題、或任何配件部分鬆散或缺失。',
        content5: '3. 貨品必須未經使用。',
        content6: '4. 貨品必須保持完好包裝、完整無缺及保留原裝包裝盒和配件。',
        content7: '5. 貨品必須於本公司網站內購買。',
        content8: '換貨程序',
        content9:
          '如果您需要辦理換貨，請確保貨品符合退換貨條件，並依照以下的程序處理：',
        content10:
          '1. 客戶均須於收貨日起計之7天內以電郵 enquiry@hksts.com.hk 提出換貨申請要求，請在主旨列明「換貨申請」，並註明訂單編號。在電郵內提供確認電郵截圖、更換貨品的資料、更換原因、相關相片證明及聯絡資料。如客戶提供的資料有缺失，這將影響換貨的時間。',
        content11:
          '2. 待收到我們的確定電郵後，我們會與您安排退回貨品的方法及時間。',
        content12:
          '3. 我們在收到退回貨品後，會確認貨品狀態是否符合換貨條件相應要求。之後我們會儘快通知您換貨申請狀況。',
        content13:
          '4. 在我們跟您確認回收方法後，您必須配合本公司在7天內完成退回過程，否則，將被視為放棄該要求而無權再向本公司申索。',
        content14:
          '5. 當上述程序完成後，我們會根據您訂單的送貨地址，再次安排送貨，並會於14天內完成，此運費將會由廠商承擔。倘若該產品出現缺貨情況，我們須待產品到貨後才能再次安排送貨，所需的時間或會超出14天，本公司將不會對您任何直接、間接、特殊或後果性的損失負上任何責任。',
        content15: '換貨運費',
        content16:
          '如符合換貨條件而需要進行換貨，我們將承擔該貨品的基本運輸費用，但不包括其他額外運輸費用。',
        content17: '有關運輸費用詳情，請參閱',
        content18: '送貨安排',
        content19: '。'
      },
      eg16: {
        title: '16. 如果我的訂單出現問題，怎麼辦？',
        content1: '如果您的訂單出現問題，請電郵聯絡我們（',
        content2: '）。'
      },
      eg17: {
        title: '17. 我可以透過網站查詢我之前的訂單嗎？',
        content1: '可以，請登入本公司網店並到｢我的帳戶」內查看您的購買記錄。'
      },
      eg18: {
        title: '18. 可否透過網站查詢網上訂單進度？',
        content1: '可以，請登入本公司網店並到｢我的帳戶」內查看您的訂單狀態。'
      },
      eg19: {
        title: '19. 請問可否提供報價單？',
        content1: '貨品價格以網店售價為準，故暫時未能提供報價單，請見諒。'
      },
      eg20: {
        title: '20. 如何查詢貨品的庫存量？',
        content1:
          '我們的庫存量是實時更新的，因此您可以在網站上最多訂購等同最大可用庫存的數量。如果您未能查看某部分貨品的庫存量，請聯繫我們的客戶服務團隊。'
      },
      eg21: {
        title: '21. 可否訂購缺貨產品？',
        content1:
          '缺貨產品未能提供訂購，但客戶留下電郵地址，待貨品補充時，我們會以電郵通知貨品已補充。'
      },
      eg22: {
        title: '22. 你們如何保障我的個人資訊？',
        content1:
          '我們非常重視您的私隱和安全。我們努力確保只以您希望的方式使用您的資訊。除非法律另有要求或允許，我們將僅在您知情和同意的情況下收集、使用或披露您的個人資料。請閱讀我們的',
        content2: '私隱政策',
        content3: '了解更多詳情。'
      },
      eg23: {
        title: '23. 在網店上購物安全嗎？',
        content1:
          '我們意識到安全存儲您提供的任何資訊的重要性。HKSTS會負責確保您的個人資料受到保護。我們嚴肅對待您的私隱。我們實施了安全的資料保障措施，包括：',
        content2:
          '(i) 只有授權僱員才可獲准存取個人資料，而且用途亦只限於許可範圍之內，以符合已制定的政策及準則； 和 ',
        content3: '(ii) 用於存取個人資料的電子安全措施，如密碼保護。'
      }
    },
    conditions: {
      title: '使用條款',
      tips1: {
        content1:
          '歡迎使用由香港安全運輸系統有限公司(「我們」、「我們的」及「本公司」) 營運的',
        content2: '(「本網站」)。',
        content3: '請仔細閱讀使用本網站及／或站內資料的使用條款。'
      },
      tips2:
        '若您使用本網站，即您已同意受使用條款約束，並接納於本網站內所刊登的「個人資料（私隱）政策聲明」（「該聲明」），且我們或會不時對其進行更新，而恕不另行通知。您應該定期查閱本頁面，留意我們可能已對該等條款作出的變更。請注意，倘若您拒絕接受該等條款，您將無法透過我們的網站使用或訂購任何產品。',
      tips3:
        '使用條款備有中文及英文版本，若中、英文版本之間有任何歧異或不一致之處，概以中文版本為準。',
      eg1: {
        title: '1. 使用本網站',
        content1:
          '1.1 本網站僅可以合法方式用於合法用途。您同意遵守有關本網站的所有適用法律法規。',
        content2:
          '1.2 您切勿侵害或試圖侵害本網站的安全，包括但不限於存取並非供您使用的資料、登入並無授權您存取的伺服器、探索、掃描或測試本網站所運用的系統或網絡的漏洞、違反本網站所採納的安全或認證措施，以及干擾其他使用者瀏覽或使用本網站。',
        content3:
          '1.3 您不得濫用本網站，蓄意引入病毒、木馬程式、蠕蟲病毒及邏輯炸彈，或任何其他惡意或技術上有害的素材，且不得以任何方式（包括但不限於透過阻斷服務攻擊或分散式阻斷服務攻擊）攻擊本網站。',
        content4:
          '1.4 倘您違反或侵犯，或我們懷疑您已違反或侵犯或試圖違反或侵犯使用條款的任何條文或任何適用法律法規，我們有絕對權力驅逐您及阻止您再次瀏覽本網站（包括但不限於封截您的網際協定位址（IP Address））， 而不會向您作出任何通知及賠償。',
        content5:
          '1.5 您違反或試圖違反使用條款（包括但不限於本條款1），將令您須承擔民事責任及有可能須承擔刑事責任，而我們會於認為必須及合宜的情況下，向您採取法律行動，包括但不限於向相關執法機關舉報及向彼等披露您的身份。'
      },
      eg2: {
        title: '2. 知識產權',
        content1:
          '2.1 本網站所有內容（包括但不限於文字、設計、圖片、圖表、標誌、圖標、圖像、排版、品牌、插圖、音頻和視頻片段、介面、代碼、軟件、其匯集、選取及排列的方式，以及本網站載有的其他專有材料）屬於我們，並受版權、商標、服務商標及／或其他所有權法所保障。',
        content2:
          '2.2 您可存取、複製、下載及列印本網站所載的材料，唯僅可供您作非商業個人用途，且不會以任何方式修改或刪除於該等材料內的任何版權、商標或其他所有權通知。您不會透過該等存取、複製、下載及列印而從有關素材獲得任何權利、所有權或權益。',
        content3:
          '2.3 對於本公司商標的使用，我們採取零容忍政策。除事先獲得本公司書面同意或法律允許之外，您不得出於任何目的對本網站任何（部分或全部）內容或商標進行複製、傳播、更改、分發、銷售、修改、發佈或儲存，或將本網站用於與我們的業務有所競爭的用途。本公司明確保留所有權利。'
      },
      eg3: {
        title: '3. 本網站的內容 ',
        content1:
          '3.1 我們並不保證本網站所載或提供有關本網站的資料的準確性、完整性、及時性、用途及其他素質，且不會就任何因本網站所載資料產生及／或與本網站資料有關的任何性質的損失（無論我們有否合理地得悉或獲告知其可能性）負上任何責任。在任何情況下，您不應僅依賴本網站中任何素材作為決策基準，而不去查找第一手及更準確、完整與及時的資料來源。您須自行承擔因依賴本網站內素材而產生的風險。',
        content2:
          '3.2 任何於本網站上的輸入錯誤、文書或其他錯誤或遺漏將會由我們隨時修正而無需承擔任何責任。',
        content3:
          '3.3 我們保留權利在通知或沒有通知您的情況下暫時或永久地修改或撤回本網站（或當中任何部分）。於任何情況下，我們不會就本網站的任何修改或撤回向您承擔任何責任。'
      },
      eg4: {
        title: '4. 通訊及所提交資料 ',
        content1:
          '4.1 您同意及准許透過本網站或就本網站向我們披露、提交或建議的任何及所有意見、訊息、數據、建議、設計、概念及其他項目或素材（統稱為「所提交資料」）並非保密。我們並無責任(i)維護提交的保密性（不包括與前述的除外所提交資料有關者）；(ii)因為或就使用任何所提交資料向您或任何人士支付補償；或(iii)回應任何所提交資料。',
        content2: '4.2 您聲明及保證您所提交的資料（包括但不限於）不得',
        content3: '(i) 違反任何適用法律或法規；',
        content4:
          '(ii) 下達任何投機性、虛假或欺詐性訂單（否則將導致您的訂單被取消，且我們有權在我們認為適當時向相關部門進行舉報）',
        content5:
          '(iii) 侵犯其他人士的版權、商標、交易保密或其他知識產權，或侵犯他人的私隱或公開資料或其他個人權利；',
        content6:
          '(iv) 含有誹謗、破壞名譽、淫穢、色情、造謠中傷、威脅、辱罵或仇恨成份；',
        content7:
          '(v) 宣傳或招徠資金、貨品、服務，或散播任何垃圾郵件、大量寄發郵件、連鎖信、計劃及其他形式的招徠（除非本網站性質需要進行該等活動）；',
        content8:
          '(vi) 載有病毒、木馬程式、蠕蟲程式、毀損檔案、或其他對他人電腦構成損害的素材；或',
        content9:
          '(vii) 使用虛假電郵地址、冒充任何人士或團體，或以其他方式就所提交資料的來源誤導我們。'
      },
      eg5: {
        title: '5. 連結至其他網站',
        content1:
          '倘本網站提供往任何其他第三方營運的網站的連結，該等連結僅為方便您使用，不應視作我們以任何形式支持批准該等網站的內容。倘您瀏覽該等網站，須自行承擔您的風險，並受有關網站內的適用條款及條件所限。我們不會就該等網站是否存在，以及當中所載的內容承擔任何責任，且不會就此發表任何聲明、保證或承諾（不論為明示或暗示）。我們不會就因您使用該等網站而產生的任何損失、損害及其他責任而負責。任何有關該等網站的問題或意見應向該等網站的營運商及／或擁有人反映。'
      },
      eg6: {
        title: '6. 免責聲明及責任限制  ',
        content1:
          '6.1 鑒於科技及在線環境屬不可預測，我們並不保證（不論明示或暗示）本網站的功能、運作或連接能力會不受干預及無誤，亦不保證當中的缺陷會得到修正，或本網站或可供本網站使用的伺服器將無病毒、木馬程式、蠕蟲程式或其他有害成份。您有責任確保您備有合適的設備以正常瀏覽及使用本網站，並排除任何可能損害您電腦的事項。我們不會就由您使用或未能瀏覽本網站及／或與之有關的任何事項承擔責任。',
        content2:
          '6.2 本網站及其內容按「現狀」提供，風險須由您自行承擔，且無任何形式的保證。在適用法律法規所允許的最大程度下，我們不會就本網站及當中所載資訊作出任何形式的保證（不論明示或暗示）。',
        content3:
          '6.3 我們不會就任何因天災、火災、洪澇、意外、疫症、暴亂、戰爭、恐怖襲擊、政府干預、禁運、示威、勞資糾紛、設備故障（包括但不限於互聯網系統故障），或任何超出我們合理控制的原因，而直接或間接引致我們未能、延誤及違反我們的責任及義務而承擔任何責任。',
        content4:
          '6.4 儘管本條款中存有任何相反的規定，您如因本公司違反此等條款及細則（無論是因違約、侵權或其他行為）而招致損害，本公司的責任僅限於您購買商品之原價，及任何因我們違反本協議而招致的可預見損害。可預見損害乃本公司在接受您訂單時，您及本公司所能預見之損害。 ',
        content5:
          '6.5 在法律允許的範圍內，我們在此表明，對所有因使用本網站而引致的損失、損害及/或支出一律概不負責。針對但不局限於上述的一般性原則，本公司將不會對任何電腦裝置、軟件或數據損失或損壞，任何財務損失，間接、特殊或後果性的損失或損壞負上任何責任。 ',
        content6:
          '6.6 在本網站所提供的智全網貨品，根據本公司進行的反覆測試所得出的數據，如您遵照本公司指示使用智全網，智全網將可重複使用。倘若因人為蓄意損害、意外、您或任何第三方忽略、沒有遵照本公司指示使用貨品，或在未經本公司同意下進行的修改或維修而引起的缺陷等，而令智全網未能重複使用，本公司將不會承擔任何責任。',
        content7:
          '6.7 如個別客戶訂購的數量較大，可能會超出我們的庫存，我們須向廠商大量訂貨，貨品的製作時間及運輸至香港的時間大約為40-60天 (請留意，這只是估算，時間須按實際情況而定。)；到達香港倉庫後，亦需時再運送給客戶。倘您仍然選擇購買，即表示您知悉及明白購買所需要的時間。本公司將不會對您任何直接、間接、特殊或後果性的損失負上任何責任。'
      },
      eg7: {
        title: '7. 我們的產品',
        content1:
          '7.1 在對網上可供購買的產品進行描述時，本公司儘量確保描述準確。然而，本公司不保證產品描述或任何本公司服務的其他內容準確、完整、可靠、最新或沒有錯誤。',
        content2:
          '7.2 請注意：網站所顯示的重量、測量、大小尺寸和性能乃按最佳估計提供。製造過程中可能存在變化，這意味著交付的產品在外觀方面或與本網站顯示及描述存在細微差異。',
        content3:
          '7.3 儘管本使用條款中存有任何相反的規定，本公司保證所供應之所有產品的品質優良，合理地切合所需，且在網站上顯示的特定期間內並無任何工藝或材料缺陷。此保證不會影響您作為消費者的法定權利。 ',
        content4:
          '7.4 本公司商品的保證，並不適用於因正常損耗、人為蓄意損害、意外、您或任何第三方忽略、沒有遵照本公司指示使用商品，或在未經本公司同意下進行的修改或維修而引起的缺陷。',
        content5:
          '7.5 於本網站下載的材料乃按「原樣」提供，而使用下載內容的風險由您自行承擔。下載的內容可能含有可能導致您的電腦系統運行中斷的重大或非重大程式錯誤、漏洞及病毒。您必須在存取前對下載的內容進行病毒篩查。 ',
        content6:
          '7.6 除法律無法限制或排除的條款部分外，所有細則、保證、聲明或其他條款（無論明示或暗示）均不適用於任何下載的內容。 '
      },
      eg8: {
        title: '8. 商品供應',
        content1:
          '所有商品訂單均需視乎商品的供應情況。我們會儘量確保庫存及庫存數量的準確性，倘若商品暫出現缺貨或供貨困難，您須待庫存補充後才能建立訂單。商品補充時間將根據供應商工廠的製造及運輸時間而定，這將超出我們承諾的送貨時間。本公司將不會對您任何直接、間接、特殊或後果性的損失負上任何責任。'
      },
      eg9: {
        title: '9. 定價及收費',
        content1: '9.1 本網站的所有價格和收費均為港元計算。',
        content2:
          '9.2 本公司將盡一切合理努力確保網站的所有價格資訊均為正確的最新價格，但可能發生錯誤（包括處理錯誤）。請注意，我們並無義務按照錯誤的（更低）價格向您提供商品。',
        content3: '9.3 貨品價格並不包括運輸費用或自提貨品所產生的費用。',
        content4:
          '運輸費用將另外計算並會於賬單上顯示。請留意，賬單上的運輸費用(已包括隧道費/路費) 將按送貨安排中所列之標準計算，其他可能出現的雜費包括但不限於9.4項所列的，將以實報實銷方式由買方直接以現金支付送貨司機，本公司將不會對此行為負上任何責任。',
        content5:
          '自提貨品所產生的費用不會在賬單上顯示，這費用包括但不限於9.5項所列的，並將由買方直接以現金支付，本公司將不會對此行為負上任何責任。',
        content6:
          '9.4 如選擇送貨安排，將會收取運輸費用，這將在訂購過程中明確顯示。交易金額將在帳單和付款確認單中顯示，但並不包括其他可能出現的雜費（包括但不限於）：',
        content7: '(i)入閘費',
        content8: '(ii)登記費',
        content9: '(ii)停車費',
        content10: '(iv)超時等候費',
        content11:
          '上述可能出現的費用（包括但不限於）將根據實際情況，以每次訂單計算實報實銷方式，由買方直接以現金支付送貨司機。倘若買方並無支付上述費用，司機將拒絕交貨，同時所產生的一切損失將於在已支付的費用中扣除。',
        content12:
          '9.5 如選擇自提安排，買方將自行到倉庫提取有關貨品，並須以現金支付雜費，雜費包括但不限於：',
        content13: '(i)入閘費',
        content14: '(ii)登記費',
        content15:
          '上述雜費以每次訂單計算實報實銷方式，由買方直接以現金支付。倘若買方並無支付上述費用，倉庫將不會發貨，本公司將不會對此行為負上任何責任。',
        content16: '9.6 有關運費及其他可能出現的費用安排，請參閱',
        content17: '送貨及自提安排',
        content18: '。'
      },
      eg10: {
        title: '10. 付款方式',
        content1: '有關付款方式及程序安排，請參閱',
        content2: '付款方式',
        content3: '。'
      },
      eg11: {
        title: '11. 送貨安排',
        content1:
          '11.1 倘若我們的商品供應由於超出控制範圍的外部因素而出現延誤，我們將盡快聯絡並知會您，並將採取措施盡可能減少延誤的影響。請注意，對於此情況導致的延誤，我們概不會承擔責任。 ',
        content2:
          '11.2 倘若買方未能在約定的時間收貨，買方有可能需要承擔額外費用，而這些費用將根據實際情況，以實報實銷方式，由買方直接以現金支付送貨司機，本公司將不會對此行為負上任何責任。',
        content3: '11.3 有關送貨安排詳情及程序，請參閱',
        content4: '送貨安排',
        content5: '。'
      },
      eg12: {
        title: '12. 自提安排',
        content1:
          '12.1 倘若買方未能在約定的時間提貨，買方有可能需要承擔額外費用，而這些費用將根據實際情況，以實報實銷方式，由買方直接以現金支付倉庫，本公司將不會對此行為負上任何責任。',
        content2: '12.2 有關提貨安排詳情及程序，請參閱',
        content3: '自提安排',
        content4: '。'
      },
      eg13: {
        title: '13. 換貨安排',
        content1: '有關換貨的詳情及程序，請參閱',
        content2: '換貨政策',
        content3: '。'
      },
      eg14: {
        title: '14. 損失風險和貨物所有權',
        content1:
          '14.1 所有從本網站購買的商品均符合裝運合約的規定，即該等商品的損失風險和貨物所有權，將在我們交付給承運人當下轉移至您身上。',
        content2:
          '14.2 商品的擁有權僅會在本公司收到商品的全數款項（包括運費）後，或在我們將商品交付給承運人後轉交至您，以時間較後者為準。'
      },
      eg15: {
        title: '15. 本條款的變更',
        content1:
          '15.1 本公司保留一切權利，自行決定隨時更改、修改、增加或刪除此等條款的任何部分，而無須通知您。本公司會將條款變更刊載至本網站上，此等條款的底部日期亦會更新為最後修改日期。本公司建議您定期閱覽此等條款，留意適用於您對網站使用及您產品付運的最新條款。 ',
        content2:
          '15.2 倘若您在條款變更後或更新後繼續登入及使用網站，即代表您接受新條款。您有責任定期閱覽本網站，留意此等條款是否有變。',
        content3:
          '15.3 倘若任何此等條款或合約內的任何條款被權威機構判定為無效、違法或不可執行（不論任何程度），此等條款或規定將依據相應程度從其他條款、細則及規定中分離，其他部分將繼續在法律允許的最大範圍內有效。 ',
        content4: '15.4 在未經本公司書面同意前，不可變更或修改本條款。'
      },
      eg16: {
        title: '16. 彌償',
        content1:
          '您不可撤回地及無條件地同意就您使用本網站或違反使用條款所產生及／或與此有關的索賠、行動、要求、債項、開支、損失、損害及其他費用，向我們、我們的高級職員、董事、代理人、僱員、承辦商、授權人、其他代表及聯屬公司作出辯護、彌償及令其免受損害。'
      },
      eg17: {
        title: '17. 私隱政策',
        content1: '我們尊重您的私隱，並致力於保護您的個人資料。詳情請參閱',
        content2: '該聲明',
        content3: '。'
      },
      eg18: {
        title: '18. 完整協議 ',
        content:
          '使用條款構成我們與您就使用本網站所訂立的完整協議，且替代及取代所有其他通訊（書面或口頭）、討論、信函及有關標的事項而訂立的合約。'
      },
      eg19: {
        title: '19. 分配 ',
        content:
          '未經我們事先書面准許下，您不得轉授或轉讓您於使用條款項下具有的任何權利及責任。'
      },
      eg20: {
        title: '20. 豁免',
        content:
          '我們未能或延誤行使使用條款項下的任何權利、權力或糾正並不構成相關豁免，且任何單一或部分行使相同項目，不會妨礙我們進一步行使相同項目或行使任何其他權利、權力或糾正。在不規限上文所述之下，我們就您違反使用條款任何條文所作的豁免不得視作其後違反相同條文或本使用條款任何其他條文的豁免。'
      },
      eg21: {
        title: '21. 分割性',
        content:
          '使用條款所載每項條款的條文須予獨立執行，而其有效性不會因任何其他條文失效而受影響。倘使用條款的任何條文被釐定為不合法、無效或不可執行，其餘使用條款條文的有效性及執行性將不受影響，而為取代該等不合法、無效或不可執行的條文，須加入一項或更多相近而按照適用法例屬於合法、有效而且可予執行的條文，作為使用條款的一部分。'
      },
      eg22: {
        title: '22. 規管法例及糾紛排解',
        content:
          '使用條款須受中華人民共和國香港特別行政區（「香港特別行政區」）法例規管，並據此進行詮釋。您不可撤回地同意香港特別行政區法院就任何使用條款或其違反、終止及失效所產生或與之有關連的任何索賠或糾紛，具有獨家司法管轄權。'
      },
      eg23: {
        title: '23. 查詢',
        content1: '倘您對本網站有任何查詢，請電郵至',
        content2:
          '。我們會在可行情況下盡量即時回應您的每項查詢，但我們不會就任何延誤回應或不回應承擔責任及／或法律責任。'
      }
    },
    privacy: {
      title: '私隱政策',
      tips: '個人資料（私隱）政策聲明（「聲明」）',
      eg1: {
        title: '1. 收集政策及目的',
        content1:
          '我們（香港安全運輸系統有限公司）承諾會完全符合香港法例第486章《個人資料（私隱）條例》的規定。為達到此目的，我們會確保我們的員工達至最嚴謹的安全及保密指標。',
        content2: '懇請您細讀此聲明，以了解我們於處理您於',
        content3:
          '（「本網站」）向我們提供的個人資料時，所採用的政策及慣例。如您使用本網站，則表示已同意我們根據聲明收集、使用及披露您的個人資料。',
        content4:
          '務請注意，本聲明所載的條文或會於我們認為需要時不時予以更新、修訂、更改及修改。您於作出修訂後繼續使用本網站，將被視為接納經修改的聲明。您有責任定期查閱聲明中所作出的任何修改。',
        content5:
          '聲明備有中文及英文版本，中、英文版本的聲明如有任何歧異或不一致之處，概以中文版本為準。'
      },
      eg2: {
        title: '2. 收集個人資料的用途 ',
        content1:
          '當您使用本網站，包括當您自願於本網站註冊帳戶或更新您現有的個人資料、發電郵給我們、使用我們的客戶服務、參加促銷活動或向我們的網站提交任何資訊，我們可能要求您提供個人資料。',
        content2:
          '請注意，在登記成為用戶及使用本網站時，您會被要求向我們提供個人識別資料，包括姓名、電郵地址、送貨/帳單地址、電話號碼、手提電話號碼、公司名稱、商業登記副本、公司註冊證明副本*等，而我們會將您視作合法提供者，您須聲明及保證您所提交的資料不得',
        content3: '(i) 違反任何適用法律法規；',
        content4:
          '(ii) 侵犯其他人士的版權、交易保密或其他知識產權，或侵犯他人的私隱或其他個人權利。所有就由您提交的資料產生的任何法律問題及責任須由您 (即合法提供者) 承擔，本公司將不會對此承擔任何責任。',
        content5:
          '我們有必要收集用戶之資料，以便向用戶提供本網站之各項服務及活動。倘若用戶未能提供所需資料，我們將不能向用戶提供我們網站上可供享用之服務及活動。',
        content6:
          '*上傳有關副本目的基於保安理由，要確保申請公司符合《商業登記條例》及《公司條例》所有規定，以及用以核實用戶資料。',
        content7:
          '我們於本網站收集的資料及數據會用於（包括但不限於）以下用途：',
        content8:
          '2.1 處理您的訂單並向您提供我們的各項服務、網上購物、營銷材料、項目資訊及推廣訊息；',
        content9: '2.2 處理及跟進服務要求、查詢及投訴；',
        content10:
          '2.3 就與您使用本網站及／或我們提供的服務有關的行政通告及通訊，與您聯絡；',
        content11: '2.4 核實您的身份；',
        content12:
          '2.5 取得有關網站使用者統計數據，以分析網站的使用狀況，從而協助其進一步發展；',
        content13: '2.6 了解您的需要並向您提供個人化服務；',
        content14: '2.7 檢討我們宣傳及營銷活動的效果；及',
        content15:
          '2.8 使用已登記之電郵地址進行有關本公司產品及服務之市場推廣。',
        content16:
          '您有權按本政策所提供的聯絡方法向本公司提出要求停止收取推廣資訊。'
      },
      eg3: {
        title: '3. 收集本網站使用者的個人數據',
        content1:
          '您進入本網站時，我們會紀錄您的域名服務器地址及／或電郵地址，跟蹤您所瀏覽的頁面，並於「cookies」內儲存有關資料。這個程序會在您不知悉的情況下進行。一般而言，我們會利用「cookies」估算受眾規模、估計本網站內各部分的受歡迎程度，跟蹤使用者的走向及參加推廣活動的人次，以及計量使用者的人流模式。除第4段所列明的情況外，以「cookies」方式收集的資料及數據將不予披露。'
      },
      eg4: {
        title: '4. 披露個人資料',
        content1:
          '我們同意採取所有可行步驟，將您個人資料保密及／或不予披露，惟在以下情況，上述資料及數據或會予以披露及／或轉交：',
        content2:
          '4.1 向經我們正式授權可使用上述資料及／或數據的任何第三方供應商或服務供應商提供資料，有關人士將協助我們的服務；',
        content3:
          '4.2 向經我們正式授權可使用上述資料及／或數據的任何市場推廣之代理人、顧問、或附屬公司提供資料；',
        content4: '4.3 當我們需要保障及維護我們的權益及財產時；',
        content5: '4.4 當我們認為我們必須提供資料以遵守適用法律法規時；及',
        content6: '4.5 當我們認為必須提供資料以維持及改進我們服務時。',
        content7:
          '如您使用本網站，則會被視為已同意、准許及授權我們在上述情況下，披露及轉交您的個人資料。'
      },
      eg5: {
        title: '5. 連接至其他網頁',
        content1:
          '為向您提供更多資訊，本網站會提供其他網頁的連結，包括但不限於若干社交媒體網站（例如Facebook）。當您瀏覽該等其他網頁及使用當中所提供的服務時，您的個人資料或會被收集。倘您決定點擊本網站的廣告及超連結，使您連接至另一網頁，則對您視為私人及保密的個人資料的保障或會於該等其他網頁上顯露。',
        content2:
          '聲明僅適用於本網站。茲提醒您，聲明並不保障您的個人資料在本網站以外的網頁可能會承受的風險。如有需要，您可於您的電腦／瀏覽器上調整對該等其他網頁的私隱／保安設定。為更有效地保障您的私隱權，懇請您參閱該等其他網頁的私隱政策。我們概不為您使用該等其他網頁承擔任何責任或法律責任。'
      },
      eg6: {
        title: '6. 安全',
        content1:
          '我們採取合理措施確保我們收集的個人資料未經授權而被披露、濫用或丟失。除非法律另有要求或允許，我們將僅在您知情和同意的情況下收集、使用或披露您的個人資料，包括私隱政策中規定的內容。',
        content2:
          '我們會負責確保您的個人資料受到保護。我們嚴肅對待您的私隱。我們實施了安全的資料保障措施，包括：',
        content3:
          '(i) 只有授權僱員才可獲准存取個人資料，而且用途亦只限於許可範圍之內，以符合已制定的政策及準則；和 ',
        content4: '(ii) 用於存取個人資料的電子安全措施，如密碼保護。',
        content5:
          '請您放心，您的個人資料僅會用於需要達成上文第2段所述的收集目的，除非您要求我們停止將資料使用於該等用途。如您有此要求，或會使我們未能繼續向您提供服務、資訊及／或推廣資料，使您可於日後受惠。'
      },
      eg7: {
        title: '7. 營銷資料',
        content:
          '我們或會根據您向我們提供的個人資料，不時以電子郵件向您傳送營銷及推廣資料，以及有關我們的產品、服務及類似事項的資料。倘您不希望收到我們的營銷及推廣資料及資訊，您可按本政策所提供的聯絡方法向本公司提出取消接收資訊要求。'
      },
      eg8: {
        title: '8. 存取數據 ',
        content1:
          '8.1 您有權查取我們持有有關您的個人資料及要求更正或者刪除您的個人資料。',
        content2:
          '8.2 倘您對本私隱政策存有任何疑問，或者您希望查詢、更正或刪除我們持有您的個人資料，請在「我的帳戶」頁面編輯或更新您的個人資料或請直接發送要求到如下地址︰',
        content3: '電郵地址：',
        content4:
          '8.3 就處理任何查閱或更正個人資料的要求，我們保留權利向您收取合理費用。收到請求後，我們或會要求您提供個人詳細資料以核實您的身份，並會盡量於收到您的要求後30天內回應。'
      },
      eg9: {
        title: '9. Cookies政策  ',
        content1:
          'Cookies是網站在您的電腦或移動裝置上放置的小型數據文件，用於收集有關您使用網站的訊息，以在您瀏覽時「記住」您，以及在網站內外為您度身制訂產品、服務和廣告。Cookies通常對網站的功能非常重要。',
        content2:
          '當您透過電腦或裝置瀏覽本公司網站時，某些Cookies會收集有關您瀏覽和消費行為的訊息。這些訊息包括有關您所查看過的頁面、購買的產品和您在網站上的瀏覽過程。我們並可能會使用cookie來監察您的瀏覽和消費行為。然而，我們不會使用Cookies來收集或記錄您的姓名、地址或其他聯絡資料。 ',
        content3: '使用Cookies的主要原因：',
        content4:
          '9.1 基於技術需要，為了使我們的網站能有效營運，尤其是網上交易和網站導航方面；',
        content5:
          '9.2 讓本公司能向您推廣，特別是網頁廣告和有針對性的資訊更新； ',
        content6:
          '9.3 讓本公司能夠收集有關您瀏覽和購物模式的資訊，包括監察推廣活動是否成功等；',
        content7:
          '如要停用Cookies，您需要更改網站瀏覽器的設定，實際步驟取決於您所使用的瀏覽器。一般來說如果停用了 Cookies，網站或未能妥善運作。如果您只是停用了第三方的Cookies，則您仍然可以在我們的網站進行購物。如您停用了所有Cookies，則可能無法在我們的網站完成購物。'
      },
      eg10: {
        title: '10. 查詢 ',
        content: '如有任何查詢，可以透過電郵與我們聯繫，電郵: '
      }
    },
    delivery: {
      title: '送貨安排',
      eg1: {
        title: '1. 送貨須知',
        content1: '1.1 本公司提供的送貨服務，需另外收費。',
        content2:
          '1.2 送貨司機只負責貨物運輸部分及落貨，但不包括搬運貨物。客戶須自行安排將貨物搬運至貨倉。',
        content3:
          '1.3 如送貨地區內因大型公眾活動，例如：慶祝活動、示威遊行等而封路，送貨時間可能再作安排，一切以專人聯絡客戶之日期與時間為準。',
        content4: '1.4 送貨車隊將會於送貨前聯絡客戶通知抵達時間。',
        content5: '1.5 暴雨警告及颱風送貨安排：',
        content6:
          '(i) 黃色、紅色暴雨警告或三號颱風訊號或以下：送貨服務維持正常，但可能會因路況惡化而有延誤。',
        content7:
          '(ii) 黑色暴雨警告、八號颱風訊號或以上懸掛或因天氣影響引致車輛停駛、嚴重水浸、道路阻塞或封閉：送貨服務將會暫停，送貨日期及時間將另作安排。',
        content8:
          '1.6 當貨品送到指定收貨地址時，客戶需要簽收貨品，以證明貨品之責任已轉移至收貨人。如果收貨人並非付款人，您亦承認收貨人的簽署已證明本公司已完成發貨及送貨之責任。',
        content9:
          '1.7 本公司有權就送貨安排條款作出任何修改，恕不另行通知及保留有關交易之最终決定權。如上述之中文版本與英文版出現歧異，概以中文版本作準。'
      },
      eg2: {
        title: '2. 送貨範圍及時間',
        content1: '2.1 所有送貨服務以每訂單計算，並只限單一送貨地點。',
        content2:
          '2.2 送貨範圍只包括新界區、九龍區及機場範圍的主要工商業區，並且必須有落貨區；其他地區或非工商業區的運輸費用須另議。',
        content3:
          '2.3 送貨將安排於每個星期三及星期六進行 (公眾假期除外**)，而送貨前㇐日的下午 二時為截數期限;只要客戶在送貨前㇐日的下午二時前完成付款，我們將安排於該 最近的送貨日送貨(星期三或六) ，服務時間會因不同地區而有所不同，恕不能指定 送貨日期及時間。',
        content4:
          '**請留意:如該送貨日為公眾假期，送貨將安排於之前㇐日進行 或 由專人通知相關 客戶送貨日子，而截數期限將會是之前㇐日下午二時。'
      },
      eg3: {
        title: '3. 運輸費用',
        content1:
          '運輸費用 (包括標準送貨費用及即日送貨附加費) 按每訂單計算，所有送貨服務只限㇐ 個送貨地點。',
        content2: '',
        content3: '(i) 標準送貨費用:',
        content4: {
          title1: '地區',
          title2: '收費 (每個地點)',
          title3: '備註',
          type1: '九龍區',
          type2: '新界區',
          type3: '機場範圍',
          price1: '$250.00',
          price2: '$250.00',
          price3: '$320.00',
          remarks: '費用上限包括運送16箱貨物，之後額外每箱加$10.00'
        },
        content5: '(ii) 即日送貨附加費: (可選擇的)',
        content6: '如需即日送貨，請於當天下午㇐時前訂貨及完成付款程序，便能安排即日送貨， 但須繳付附加費 。'
      },
      eg4: {
        title: '4. 額外運輸費用',
        content1:
          '此部分的費用將不會於賬單上顯示，會按實際需要而產生，以每次運送計算，實報實銷方式收取，由買方直接以現金支付送貨司機。倘若買方並無支付上述費用，司機將拒絕交貨，同時所產生的一切損失將於在已支付的費用中扣除。請留意，這部分收費將不設退款。',
        content2: '額外運輸費用包括但不限於以下項目：',
        content3: '(i) 超時等候費',
        content4:
          '首 30分鐘的等候時間不用收費，其後超時等候為每小時 $200.00，不足1小時亦當1小時計算。',
        content5: '(ii) 登記費、停車場費、入閘費或 其他任何雜費',
        content6:
          '司機送貨前將致電收貨人，如有方法豁免相關雜費，請預先通知送貨司機安 排。'
      },
      title2: '自提安排',
      eg5: {
        title: '1. 提貨須知',
        content1:
          '1.1 於訂單付款確定後，客戶會收到附有提貨單的電郵。客戶須在指定日子及時間內自提貨品。請参閱第2部分提貨地點及時間資料。',
        content2: '1.2 所有自提安排以每訂單計算，並只限一次性提貨。',
        content3:
          '1.3 客戶須於提貨時出示有效的提貨單及公司印章簽收。如公司印章與提貨單公司名稱不符，及/或如未能提供提貨單及/或公司印章，貨倉將不會發貨 ；而一切因未能提貨所產生的車費、路/橋費 、入閘費 、登記費 、等候費等，一概不獲追討及/或退還。',
        content4:
          '1.4 貨品價格不包括自提貨品所產生的費用。客戶自行到倉庫提取貨品， 必須支付雜費，雜費包括入閘費及登記費，詳情請參閱收費安排。 這些雜費以每次提貨計算實報實銷方式，由客戶支付倉庫。倘若客戶並 無支付上述費用，倉庫將不會發貨，本公司不會對此行為負上任何責任。',
        content5:
          '',
        content6: '1.5 暴雨警告及颱風送貨安排：',
        content7:
          '(i) 黃色、紅色暴雨警告或三號颱風訊號或以下：自提安排維持正常，但可能會因路況惡化而有延誤。',
        content8:
          '(ii) 黑色暴雨警告、八號颱風訊號或以上懸掛或因天氣影響引致車輛停駛、嚴重水浸、道路阻塞或封閉：自提安排將會暫停，日期及時間將另作安排。',
        content9:
          '1.6 當客戶簽收貨品後，貨品之責任將已轉移至提貨人。如果提貨人並非付款人，您亦承認提貨人的簽署已證明本公司已完成發貨之責任。',
        content10:
          '1.7 本公司有權就自提安排條款作出任何修改，恕不另行通知及保留有關交易之最终決定權。如上述之中文版本與英文版出現歧異，概以中文版本作準。'
      },
      eg6: {
        title: '2. 提貨須知',
        content1:
          '2.1 貨倉地址：新界元朗新田錦壆路53號 (威盛倉內) 空運貨物安檢中心有限公司',
        content2: '2.2 提貨日子及時間**：星期一至星期六，上午9時至下午6時',
        content3:
          '**請留意:上述只是估計的時間，提貨日子及時間或會因不可預測的原 因而有所不同。本公司有權就自提安排作出任何修改，恕不另行通知及 保留有關之最终決定權。'
      },
      title3: '收費安排',
      eg7: {
        title:
          '貨品價格不包括自提貨品所產生的費用。客戶自行到倉庫提取貨品，必須以現金支付雜費，雜費包括入閘費及登記費。',
        content1: '(i) 收費項目：',
        content2: {
          title1: '收費項目',
          title2: '時段',
          title3: '收費',
          type1: '入閘費',
          type2: '登記費 / 手續費',
          time1: '由 09:00 至 17:59',
          time2: '由 18:00 至 08:59',
          price1: '(每車) $150',
          price2: '(每車) $200',
          price3: '(每車) $100'
        },
        content3:
          '**請留意：上述只是參考收費，一切以倉庫方面收費為準。 本公司有權就上述收費安排作出任何修改，恕不另行通知及保留最终決定權。'
      }
    },
    exchange: {
      title: '換貨政策',
      eg1: {
        title: '1. 換貨政策',
        content1: '「7天購物保證」',
        content2:
          '我們為您提供了「7天購物保證」(必須符合「換貨條件」條件)，在本網站售賣的產品均可於收到貨品起計7天內換貨（以送貨簽收記錄為准），您可以放心在此購物。換貨前請先與我們聯絡，以作出適當的安排。請注意，所有已被確認付款的訂單將不能取消，以及不設退款安排。',
        content3:
          '本公司有權就換貨政策條款作出任何修改，恕不另行通知及保留有關交易之最终決定權。如上述之中文版本與英文版出現歧異，概以中文版本作準。'
      },
      eg2: {
        title: '2. 換貨條件',
        content1: '您將更換之貨品必須符合以下條件，否則，恕不接受換貨申請：',
        content2: '2.1 貨品須於簽收後7天內提出更換。',
        content3:
          '2.2 未使用前已發現貨品有任何非人為因素引致的破損、品質問題、或任何配件部分鬆散或缺失。',
        content4: '2.3 貨品必須未經使用。',
        content5: '2.4 貨品必須保持完好包裝、完整無缺及保留原裝包裝盒和配件。',
        content6: '2.5 貨品必須於本公司網站內購買。',
        content7:
          '2.6 如有任何爭議，香港安全運輸系統有限公司保留最終決定權，不得異議。'
      },
      eg3: {
        title: '3. 換貨須知',
        content1:
          '本公司的服務一直專業謹慎，我們會盡力確保所有貨品的品質，並在貨品離開倉庫前確保貨品為正確無誤。如您收到貨品後，發現有任何非人為因素引致的破損、品質問題、或任何配件部分鬆散或缺失，請按照「更換產品程序」更換產品。',
        content2:
          '3.1 倘若閣下發現包裝損壞，請在送貨員面前，先檢查及確認貨品是否齊全及其狀態，否則請拒絕簽收，如有任何疑問，請電郵至 ',
        content3: ' 與我們的客戶服務團隊聯絡。',
        content4:
          '3.2 您收到貨品後，發現貨品有任何非人為因素引致的破損、品質問題、或任何配件部分鬆散或缺失而需退回給我們，我們將為此承擔責任，為您安排換貨。然後，我們會根據您訂單的送貨地址，再次安排送貨，此運費將會由廠商承擔，但不包括額外運輸費用 (如超時等候費等)。',
        content5:
          '3.3 除貨品符合「換貨條件」的條件外，恕我們不接受其他原因而提出的換貨申請。',
        content6:
          '3.4 客戶如因更換貨品時不能使用貨品而引起的所有損失，均須自行承擔。'
      },
      eg4: {
        title: '4. 換貨產品程序',
        content1: '如須換貨，請依照以下的換貨程序來處理將要更換之貨品：',
        content2: '4.1 客戶均須於收貨日起計之7天內以電郵',
        content3:
          '提出換貨申請要求，請在主旨列明「換貨申請」，並註明訂單編號。在電郵內提供確認電郵截圖、更換貨品的資料、更換原因、相關相片證明及聯絡資料。如客戶提供的資料有缺失，這將影響換貨的時間。',
        content4:
          '4.2 待收到我們的確定電郵後，我們會與您安排退回貨品的方法及時間。',
        content5:
          '4.3 我們在收到退回貨品後，會確認貨品狀態是否符合換貨條件相應要求。之後我們會儘快通知您換貨申請狀況，並會安排相應的更換方法。',
        content6:
          '4.4 在我們跟您確認回收方法後，您必須配合本公司在7天內完成退回過程，否則，將被視為放棄該要求而無權再向本公司申索。',
        content7:
          '4.5 當上述程序完成後，我們會根據您訂單的送貨地址，再次安排送貨，並會於14天內完成，此運費將會由廠商承擔。倘若該產品出現缺貨情況，我們須待產品到貨後才能再次安排送貨，所需的時間或會超出14天，本公司將不會對您任何直接、間接、特殊或後果性的損失負上任何責任。'
      },
      eg5: {
        title: '5. 無法派送的訂單',
        content1:
          '部份訂單會因收貨地址不詳、無人收貨、收件人拒收貨品或拒絕簽收等原因而被退回。',
        content2:
          '無法派送的訂單，貨品將由送貨公司退回給我們，我們可以再次為客戶安排運送被退回的貨品，但客戶必須再次支付運費，收費詳情請參閱',
        content3: ' 送貨安排 ',
        content4: '。',
        content5: '倘若貨品最終未能成功送予客戶，已繳付的款項亦不設退款安排。'
      }
    },
    payment: {
      title: '付款方式',
      tips: '付款程序',
      eg1: {
        title: '1. 所有貨品之價值均以港幣計算。'
      },
      eg2: {
        title: '2. 可選擇以下形式付款：',
        content1: '(i) 轉數快 或 銀行入數或轉帳 - 銀行櫃台、自動櫃員機、網上理財',
        content2: '(ii) 由 Stripe 網上付款系統處理之Visa及Mastercard 信用卡付款',
        content3:
          '',
        content4: ''
      },
      eg3: {
        title: '3.',
        content1:
          '(i) 若選擇以轉數快 或 銀行入數/轉帳方式付款，您將收到帳單。請注意， 這並不表示我們已接受您的訂單，您仍須完成付款程序及等待訂單被確 認付款後，訂單才會被正式接受及安排送貨。',
        content2:
          '(ii) 若選擇以信用卡方式付款，您將會轉至Stripe網台頁面繼續付款程序。待付款被確認後，訂單會被正式接受及安排送貨。'
      },
      eg4: {
        title: '4. 各種付款方式的詳情：',
        content1: '(i) 轉數快 或 銀行入帳或轉帳:銀行櫃台、自動櫃員機、網上理財',
        content2:
          '若以轉數快 或 銀行入數/轉帳付款，將會收到由系統發出的繳費通知電 郵，電郵內會包括(包括但不限於)帳單、付款方式、上傳入數紙的連 結等，請於指定日子內付款，然後拍下入數紙 (必須清晰)，並點選上傳 連結將入數紙上傳於系統。',
        content3: '賬戶如下：',
        content4: '銀行：',
        content5: 'Hang Seng Bank',
        content6: '帳戶名稱：',
        content7: 'HONG KONG SECURE TRANSPORTATION SYSTEMS LTD ',
        content8: '帳戶號碼：',
        content9: '395-666100-001',
        content10: '(ii) 郵寄支票',
        content11:
          '若以郵寄方式投遞支票付款，請於支票背面寫上訂單編號，並於到期日3個工作天前寄往「香港新界荃灣海盛路3號TML廣場18樓A室 - 財務部」。請勿郵寄現金。一般而言，以郵寄支票付款需要3至5個工作天才能完成確認付款程序。',
        content12: '請注意：',
        content13: 'i) 支票須加劃線，抬頭請寫',
        content14: '「HONG KONG SECURE TRANSPORTATION SYSTEMS LIMITED」',
        content15: 'ii) 期票恕不接納',
        content16: '另外，為加快確認付款程序，請於郵寄支票當天以電郵 ',
        content17:
          ' 通知我們，請在主旨列明「郵寄支票」，並註明訂單編號。在電郵內請加入以下資料：',
        content18: '客戶編號：STSxxxx',
        content19: '訂單編號：XXXXXX',
        content20: '金額（港幣）：$XXXX.XX',
        content21: '支票號碼：XXXXXXXX',
        content22: '(ii) 信用卡 (VISA & Mastercard)',
        content23:
          '為提供安全可靠的網上購物服務，本公司的網上交易收款由 Stripe 網上付款系統處理。所有信用卡的資料包括信用卡號碼，姓名及密碼等，會經過加密，才透過互聯網傳送到第三方網上付款系統進行驗證，防止客戶的資料在網上傳送時，被未經許可的第三者閱讀，而有關資料亦不會傳送給網上商店作紀錄，真正為客戶提供一個安全無慮的購物環境。',
        content24: '付款程序',
        content25:
          '若選擇透過Stripe 網上付款系統以信用卡付款，於選擇付款方式後，會轉至Stripe網台頁面，請按指示輸入信用卡資料以完成付款程序。完成後，Stripe會通知本網店付款已完成，本網店隨後會以電郵通知閣下訂單付款已被確認 及 有關送貨的資料詳情，請依據交易確認資料於收貨時簽收以茲確認。',
        content26: '請留意：',
        content27:
          '(i) 如因透過第三方的網上付款關閘付款而招致任何損失，我們概不承擔責任。向網上付款服務供應商提供付款資料之前，您必須細閱所有相關條款及細則。',
        content28: '(ii) 任何有關信用卡付款查詢詳情，請自行與發卡銀行聯絡。',
        content29: '(iii) 為保障顧客的利益，付款者必須為信用卡持有人。',
        content30:
          '(iv) 如客戶由於個人原因更改付款方式，而需要作信用卡退款，將透過原本的付款方式獲得退款，所有因退款而產生的額外費用 (如手續費、服務費等) 須由客戶支付，而實際退款時間及相關額外費用須視乎發卡機構、銀行或電子支付機構而定。本公司將不會對此行為負上任何責任。'
      }
    }
  },
  ...zhLocale
}
