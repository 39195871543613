import 'lib-flexible'
import '@babel/polyfill'
import Vue from 'vue'
import ElementUI from 'element-ui'
import index from './index'
import router from './router'
import store from './store'
import i18n from '@/common/lang'
import Bus from 'common/bus'
import SETTING from '@/setting'
import 'common/filters'
import 'fonts/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css'
// import './registerServiceWorker'
// import from LL //
import FormData from 'form-data'
import axios from 'axios'
import VueAxios from 'vue-axios'
import md5 from 'md5'
import constValue from '@/common/config/index'
import { StripeCheckout } from 'vue-stripe'

SETTING.DEBUG &&
  import('vconsole').then(data => {
    const VConsole = data.default
    new VConsole().hide()
  })

Vue.use(ElementUI)

Vue.use(VueAxios, axios)

Vue.use(md5)

Vue.component('stripe-checkout', StripeCheckout)

Vue.config.productionTip = false

let ipCheck = localStorage.getItem('testing')
if (ipCheck != 'testingKey' && false) {
  window.location.href = 'http://www.hksts.com.hk/comingSoon.html'
} else {
  new Vue({
    router,
    store,
    i18n,
    data: {
      Bus
    },
    constValue,
    render: h => h(index)
  }).$mount('#hk-index')
}
