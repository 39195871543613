<template>
  <div class="hk-user__wrapper">
    <el-dropdown
      trigger="click"
      @command="handleSelect"
      v-show="computeloginStatus"
      >
      <div class="hk-user">
        <img :src="updateAvatar">
        <span>{{ updateUsername }}</span>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="account">
          {{ $t('header.account') }}
        </el-dropdown-item>
        <el-dropdown-item command="logout">
          {{ $t('header.logout') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="hk-user" v-show="!computeloginStatus" @click="jump('login')">
      <img :src="identity.demoImg">  
      <span>{{ $t('login.login') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HkUser',
  props: {
    identity: {
      type: Object,
      default () {
        return {
          avator: this.$store.state.config.userInfo.avatar,
          name: this.$store.state.config.userInfo.username,
          demoImg : this.$store.state.config.userInfo.dumpImg,
        }
      }
    }
  },
  data () {
    return {
      loginStatus: false, 
    }
  },
  computed : {
    computeloginStatus: function() {
      let self = this;
      let ssid = localStorage.getItem('SSID');
      if (ssid && this.$store.state.data.userInfo.username ) {
	      if(this.$store.state.data.userInfo.merchants_number){
	        this.loginStatus = true;
	
				}else{
	        this.loginStatus = false;
				}
      } else {
        this.loginStatus = false
      }
      return this.loginStatus
    },
    updateAvatar: function() {
      return this.$store.state.data.userInfo.avatar
    },
    updateUsername: function() {
      return this.$store.state.data.userInfo.username
    },  
  },
  methods: {
    handleSelect (val) {
      if (val === 'account') {
        this.checkLoginStatus();
      } else if (val === 'logout') {
        this.logout();
      }
    },
    jump (name) {
      this.$route.name !== name && this.$router.push({ name })
    },
    getAvatar () {
      let self = this;
      let rootApi = self.$store.state.config.apiRoot;
      let header = {
        headers: {
          token: localStorage.getItem('SSID')
        }
      }
      let apiCall = rootApi + '/api/user/info?lang=zh'  
      this.axios.get(apiCall, header)
      .then(function (response) {
        return response.data.data
      })
      .catch(function (error) {
        console.log(error);
      }) 
    },    
    checkLoginStatus () {
      let self = this;
      let rootApi = self.$store.state.config.apiRoot;
      let header = {
        headers: {
          token: localStorage.getItem('SSID')
        }
      }
      let apiCall = rootApi + '/api/user/info?lang=zh'  
      this.axios.get(apiCall, header)
      .then(function (response) {
        let userType = '';
        let result = response.data;
        userType = response.data.data.userType;
        if (result.code == 0 && result.data.userType != 'GUEST') {
          self.jump('account')
        } else {
          self.jump('login')
        }
      })
      .catch(function (error) {
        console.log(error);
      }) 
    },
    logout() {
      let self = this;
      let formData = new FormData();
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/user/logout?lang=';
      let token = localStorage.getItem('SSID');
      formData = {};
      // USER LOGOUT => 
      this.axios.post(
        apiCall, formData, 
        {
          headers: {
            'token': token,
          }
        }
      )
      .then(function (response) {
        let code = response.data.code;
        switch(code) {
          case 0:
            self.$store.commit('initUser');
            self.jump('login');
            break
          case 401: 
            self.$store.commit('initUser');
            self.jump('login');
            break
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.hk-user {
  display: flex;
  align-items: center;
  font-size: $textSize;
  color: $subTitleColor;
  cursor: pointer;

  span {
    overflow: visible;
  }

  img {
    width: 64px;
    height: 64px;
    margin-right: 12px;
    border-radius: 50%;
  }

  span {
    display: block;
    max-width: 180px;
    @extend %text-overflow;
  }
}

@include mobile {
  .hk-user__wrapper {
    flex: auto;

    .hk-user {
      font-size: 26px;
    }
  }
}

.el-dropdown-menu__item {
  display: flex;
  align-items: center;
  padding: 10px 50px;
  font-size: $descriptionSize;
  color: $textColor;

  @include mobile {
    font-size: 30px;
    margin: 10px 0;
  }
}
</style>
