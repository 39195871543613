<template>
  <div class="hk-footer">
    <ul class="hk-footer__menu">
      <li
        v-for="(option, index) in options"
        :key="index"
        class="hk-footer__button"
        @click="handleClick(option.to)"
      >
        {{ option.label }}
      </li>
    </ul>
    <p class="hk-footer__copyright">{{ copyright }}</p>
  </div>
</template>

<script>
export default {
  name: 'HkFooter',
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      copyright: process.env.VUE_APP_COPYRIGHT
    }
  },
  methods: {
    handleClick (name) {
      this.$route.name !== name && this.$router.push({ name })
    }
  }
}
</script>

<style lang="scss" scoped>
.hk-footer {
  max-width: 1400px;
  margin: 100px auto 0 auto;
  font-size: $subTitleSize;
  line-height: 32px;
  color: $descriptionColor;

  .hk-footer__menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 35px 0;

    .hk-footer__button {
      margin: 10px 35px;
      cursor: pointer;

      @include mobile {
        width: 170px;
        margin: 10px 0;
        text-align: center;
      }
    }
  }

  .hk-footer__copyright {
    padding: 35px 0;
    text-align: center;
  }

  @include mobile {
    font-size: 24px;
  }

  @media only screen and (-webkit-device-pixel-ratio: 3) {
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
  }
}
</style>
