<template>
  <router-view />
</template>

<script>
export default {
  name: 'HkIndex'
}
</script>

<style lang="scss">
@import '~styles/base';
</style>
