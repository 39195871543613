export default {
  base: '/about',
  routes: [{
    path: 'company',
    name: 'aboutCompany',
    component: () => import('@/pages/about'),
    meta: {
      sidebar: false,
      account: false,
      menu: false,
      title: 'company'
    }
  }, {
    path: 'contact',
    name: 'aboutContact',
    component: () => import('@/pages/about'),
    meta: {
      sidebar: false,
      account: false,
      menu: false,
      title: 'contact'
    }
  }, {
    path: 'faqs',
    name: 'aboutFaqs',
    component: () => import('@/pages/about'),
    meta: {
      sidebar: false,
      account: false,
      menu: false,
      title: 'faqs'
    }
  }, {
    path: 'conditions',
    name: 'aboutConditions',
    component: () => import('@/pages/about'),
    meta: {
      sidebar: false,
      account: false,
      menu: false,
      title: 'conditions'
    }
  }, {
    path: 'regist-conditions',
    name: 'aboutConditions',
    component: () => import('@/pages/about'),
    meta: {
      sidebar: false,
      account: false,
      menu: false,
      header: false,
      footer: false,
      submenu: false,
      title: 'conditions'
    }
  }, {
    path: 'privacy',
    name: 'aboutPrivacy',
    component: () => import('@/pages/about'),
    meta: {
      sidebar: false,
      account: false,
      menu: false,
      title: 'privacy'
    }
  }, {
    path: 'regist-privacy',
    name: 'aboutPrivacy',
    component: () => import('@/pages/about'),
    meta: {
      sidebar: false,
      account: false,
      menu: false,
      header: false,
      footer: false,
      submenu: false,
      title: 'privacy'
    }
  }, {
    path: 'delivery',
    name: 'aboutDelivery',
    component: () => import('@/pages/about'),
    meta: {
      sidebar: false,
      account: false,
      menu: false,
      title: 'delivery'
    }
  }, {
    path: 'exchange',
    name: 'aboutExchange',
    component: () => import('@/pages/about'),
    meta: {
      sidebar: false,
      account: false,
      menu: false,
      title: 'exchange'
    }
  }, {
    path: 'payment',
    name: 'aboutPayment',
    component: () => import('@/pages/about'),
    meta: {
      sidebar: false,
      account: false,
      menu: false,
      title: 'payment'
    }
  }]
}
