import enLocale from 'element-ui/lib/locale/lang/en'

export default {
  common: {
    search: 'Search',
    detail: 'Detail',
    purchase: 'Add to cart',
    favorite: 'Favorite',
    carton: 'carton',
    date: '{0} to {1}',
    stock: '{0} cartons in stock',
    outOfStock: 'Out of stock',
    size: 'Size',
    weight: 'Weight',
    color: 'Color',
    packing: 'Packing',
    unit: '{0} pcs/carton',
    avgPrice: ' Average price',
    company: 'Company',
    area: 'Area',
    areaDesc: 'District',
    deliveryAddress: 'Delivery Address',
    deliveryAddressDesc: 'Please fill in the delivery address',
    contact: 'Contact Person (CHI)',
    contactDesc: 'Please use real name',
    phone: 'Tel.',
    fillIn: 'Please fill in',
    email: 'Email',
    edit: 'Edit',
    delete: 'Delete',
    next: 'NEXT',
    prev: 'PREVIOUS',
    finish: 'FINISH',
    confirm: 'Confirm',
    confirmInvoice: 'NEXT',
    cancel: 'Cancel',
    success: 'Submitted Successfully!',
    back: 'Back to homepage',
    close: 'Close',
    payment: 'Payment done.',
    giftPrice: 'Gift value',
    paymentStatus: {
      success: 'Your payment to invoice {0} is successful.',
      failed: 'Your payment to invoice {0} has failed.。',
      offline:
        'Your offline payment to invoice {0} is under review. We have sent in the invoice to your registered email. Please check.',
      offlineNoEmail: 'Your offline payment to invoice {0} is under review.',
      unpaid: 'Your invoice {0} is unpaid.'
    },
    underReview:
      'Your account is under review. You can only perform this action after your account is approved.',
    noData: 'No Data',
    commandConfirm: 'We got the instruction',
    selectAll: 'Select All',
    unselectAll: 'Unselect All'
  },
  product: {
    net: {
      type: 'Smartsec Net Series',
      'mdp/q7': {
        info: {
          1: 'The Smartsec system is a set of transportation security measures approved by the Civil Aviation Department. It is designed to protect the inspected goods that are transported from the controlled air cargo security inspection facilities to the airport freight station to prevent the goods from being illegally interfered.',
          2: 'This set of transportation security measures requires the use of Zhiquan.com to protect the inspected goods, and the use of anti-removable security measures (numbered seals) to confirm whether the consignment is intact.'
        },
        desc: {
          1: 'Size: 125" x 96" x 118" H',
          2: 'Black net with blue belt',
          3: 'Suitable for pallet with height over 64" only, unsuitable for pallet with overhung or with height below 64" or 20ft contour'
        },
        discount: '{0}：Buy {1}；Free {2}（Average price HK$ {3}）',
        groupBuying: {
          1: 'Buy {0} cartons，Group Buying Price HK$ {1}'
        }
      },
      '20ft': {
        desc: {
          1: 'Size: 238" x 96" x 118" H',
          2: 'Black net with red belt',
          3: 'Suitable for 20ft contour, unsuitable for other contours'
        }
      },
      w2: {
        desc: {
          1: 'Size: 184" x 96" x 64" H',
          2: 'Black net with green belt',
          3: 'Suitable for pallet with overhung or with height below 64", unsuitable for pallet with height over 64" or 20ft contour'
        }
      },
      a2: {
        desc: {
          1: 'Size:125" x 96" x 64" H',
          2: 'Black net with orange belt',
          3: 'Suitable for pallet with height below 64" only, unsuitable for pallet with overhung or with height over 64" or 20ft contour'
        }
      }
    },
    seal: {
      type: 'Smartsec Seal Series',
      '19pcs/set': {
        desc: {
          1: 'White color',
          2: 'Printed with special seal number and QR code',
          3: 'Suitable for MDP/Q7, W2 and A2 net, unsuitable for 20ft net'
        }
      },
      '29pcs/set': {
        desc: {
          1: 'Orange color',
          2: 'Printed with special seal number and QR code',
          3: 'Suitable for 20ft net, unsuitable for other net'
        }
      }
    },
    details: {
      discount: '{0}：Buy {1}；Free {2}（Average price HK$ {3}）',
      groupBuying: {
        1: 'Buy {0} cartons，Group Buying Price HK$ {1}'
      }
    }
  },
  search: {
    title: 'Search Result',
    results: {
      1: {
        title: '<b>Smartsec Net</b> Series mdp/q7',
        desc:
          'The Smartsec system is a set of transportation security measures approved by the Civil Aviation Department. It is designed to protect the inspected goods that are transported from the controlled air cargo security inspection facilities to the airport freight station to prevent the goods from being illegally interfered. This set of transportation security measures requires the use of Zhiquan.com to protect the inspected goods, and the use of anti-removable security measures (numbered seals) to confirm whether the consignment is intact.'
      },
      2: {
        title: '<b>Smartsec Net</b> Series 20ft',
        desc:
          'Size:238" x 96" x 118" H Black <b>smartsec net</b> with red belt. Suitable for 20ft contour, unsuitable for other contours'
      },
      3: {
        title: '<b>Smartsec Net</b> Series w2',
        desc:
          'Size:182" x 96" x 64" H Black <b>smartsec net</b> with green belt'
      },
      4: {
        title: '<b>Smartsec Net</b> Series a2',
        desc:
          'Size:125" x 96" x 64" H Black <b>smartsec net</b> with orange belt'
      }
    }
  },
  menu: {
    net: 'Smartsec Net',
    seal: 'Smartsec Seal',
    discount: 'Group Buying'
  },
  header: {
    account: 'My Account',
    logout: 'Sign Out'
  },
  login: {
    login: 'SIGN IN/REGISTER',
    homepageLogin: 'SIGN IN',
    register: 'REGISTER',
    title: '<b>Hello, please sign in</b>',
    notRegist: 'Not yet registered?',
    registNow: 'Please register now',
    registEmail: 'Please input the email',
    sms: 'Please input the code',
    sendSms: 'Send SMS code',
    username: 'Please input the email or login ID',
    password: 'Password provided in the activation email',
    remember: 'Remember Me',
    forgot: 'Forgot password?',
    registeredMember: 'Register',
    loginId: 'Business registration Name',
    loginIdDesc: 'Please fill in the business registration name',
    companyNameCn: 'Company Name (CHI)',
    companyNameCnDesc: 'Please fill in the Chinese company name',
    companyNameEn: 'Company Name (ENG)',
    companyNameEnDesc: 'Please fill in the English company name',
    deliveryAddressDesc: 'Please fill in the complete delivery address',
    mailingAddress: 'Mailing address',
    mailingAddressDesc: 'Please fill in detailed address',
    businessRegistrationNo: 'Business Registration No.',
    businessRegistrationNoDesc: 'Please input the first 11 digits',
    businessType: 'Business Type',
    freightForwarders: 'Freight Forwarders',
    trucker: 'Trucker',
    racsf: 'Regulated Air Cargo Screening Facility (RACSF)',
    ra: 'Regulated Agent (RA)',
    others: 'Others',
    companyType: 'Company Type',
    soleProprietorship: 'Unlimited Company',
    partnership: 'Partnership',
    limitedCompany: 'Limited Company',
    username: 'Username',
    usernameDesc: 'Please fill in username',
    contactPersonCn: 'Contact Person (CHI)',
    contactPersonCnDesc: 'Please fill in the contact person (CHI)',
    contactPersonEn: 'Contact Person (ENG)',
    contactPersonEnDesc: 'Please fill in the contact person (ENG)',
    receiveContactNameZh: 'Consignee Contact Person (CHI)',
    receiveContactNameEn: 'Consignee Contact Person (ENG)',
    receiveContactNameZhDesc: 'Please fill in the consignee contact person (CHI)',
    receiveContactNameEnDesc: 'Please fill in the consignee contact person (ENG)',
    nick: 'Nick Name',
    avatar: 'Avatar',
    email: 'Email',
    emailDesc: 'Please fill in the email',
    phone: 'Phone No.',
    receiveContactMobile: 'Consignee contact number',
    phoneDesc: 'Please fill in the phone number',
    receiveContactMobileDesc: 'Please fill in the consignee contact number',
    pleaseUpload: 'Please upload',
    BRCopy: 'BR copy',
    CICopy: 'CI copy',
    clickToUpload: 'Click to upload',
    agree: 'Agree to',
    termsAndConditions: 'Terms and Conditions',
    and: ' and ',
    privacyPolicy: 'Privacy Policy',
    preview: 'PREVIEW',
    submit: 'SUBMIT',
    successfulSubmitted: 'Submitted Successfully',
    successfulEdit: 'Personal Profile is updated',
    success: {
      1: 'Please pay attention to the registered mailbox.',
      2: 'After the application is approved, we will send you an activation email.',
      3: 'Generally, we will send the email to you within 3 working days, if you do not receive the email, please call {0}.'
    },
    fail: {
      1: 'Your application has not been approved.',
      2: 'If having any questions, please call {0}.'
    },
    wait: 'Your application is still under review, please wait',
    uploadImageSuccess: 'Image was successfully uploaded.',
    uploadSuccess: 'Uploaded successfully.',
    uploadFail: 'Uploaded failed.',
    uploadImageMissing: 'Please upload payment slip',
    uploadImageFail: 'Image upload has failed.',
    uploadFormatCheck:
      'Incorrect file format. You can only upload image in JPEG / JPG / PNG / PDF.',
    uploadFormatCheckWPDF:
      'Incorrect file format. You can only upload file in JPEG / JPG / PNG / PDF.',
    uploadSizeCheck: 'The file size cannot exceed 3MB.',
    ciOnly: 'Limited company only',
    alertMessage: {
      sendSMS: {
        success: 'The verification code has been sent to your email.',
        fail: 'Failed to send verification code'
      },
      getPassword: {
        success:
          'Please login HKSTS with the password in your registered email',
        emailExist:
          'The email is used by another user. Please try with a different email.',
        invalidCode: 'Invalid verification code',
        fail: 'Wrong verification code',
        expiredCode: 'The verification code has expired. Please verify again.'
      },
      resetEmail: {
        success: 'The user email is successfully changed. Please login again.',
        emailExist:
          'The email is used by another user. Please try with a different email.',
        invalidCode: 'Invalid verification code',
        fail: 'Wrong verification code',
        expiredCode: 'The verification code has expired. Please verify again.'
      },
      resetPassword: {
        success:
          'Please login HKSTS with the password in your registered email',
        invalidCode: 'Invalid verification code',
        fail: 'Verification failed',
        notRegister: 'This email is not registered.',
        expiredCode: 'The verification code has expired. Please verify again.'
      },
      login: {
        wrongPassword: 'Wrong password',
        reviewFail:
          'Your application is rejected.\nPlease contact us at 63126760.'
      }
    },
    loginFirst: 'Please login first',
    index: 'Go back'
  },
  forms: {
    required: 'required field',
    phoneTypeError: 'Phone format error',
    emailTypeError: 'Email format error',
    emailOrPhoneTypeError: 'Email or phone format error',
    brTypeError: 'Code format error',
    brTypeMissingError: 'Please upload the copy of business registration',
    missingData: 'Required field must be filled',
    minChar: 'Minimal {0} character',
    passwordNotMatch: 'Two passwords do not match',
    oldPasswordWrong: 'Wrong old password',
    passwordChangeSuccess:
      'Password is changed successfully. Please login again.',
    eightChar: 'must be in 8 digits',
    elevenChar: 'must be in 11 digits',
    mustBeNumber: 'must be number'
  },
  home: {
    desktop: '<b>Welcome to buy<br>HKSTS “Smartsec System” products</b>',
    mobile: '<b>Welcome to<br>buy HKSTS<br>“Smartsec Systems”</b>'
  },
  account: {
    purchaseHistory: 'Purchase History',
    rebateAmountRecord: 'Rebate Amount Record',
    addressManagement: 'Address Management',
    changePassword: 'Change Password',
    informationChecking: 'Information Checking',
    date: 'Invoice Date',
    invoiceDate: 'Invoice Date',
    receiptDate: 'Received Date',
    invoiceNo: 'Invoice No.',
    invoiceNumber: 'Invoice No.',
    status: 'Status',
    amount: 'Amount',
    details: 'Details',
    view: 'View',
    rebateEarned: 'Rebate Earned',
    rebateUsed: 'Rebate Used',
    balance: 'Balance',
    prev: 'PREVIOUS',
    next: 'NEXT',
    pending: 'Pending',
    delivering: 'Delivering',
    pickUp: 'Pick-up',
    finished: 'Finished',
    paymentDesc:
      'Your payment has not been completed, please confirm that you have paid by one of the following methods',
    phoneDesc: 'Please contact HKSTS if you have already paid',
    deliveryDate: 'Delivery Date',
    deliveryAddress: 'Delivery Address',
    pickUpDate: 'Pick-up Notice',
    selectPickUpDate: 'Select the pick-up date',
    addressDesc: 'Saved delivery address ({0} items)',
    contact: 'Contact Person',
    receiptAddress: 'Delivery Address',
    phone: 'Tel.',
    default: 'Default',
    setDefault: 'Set as default',
    oldPassword: 'Old Password',
    oldPasswordDesc: 'Please fill in the old password',
    newPassword: 'New Password',
    newPasswordDesc: 'Please fill in the new password',
    newPasswordAgain: 'Input the new password again',
    newPasswordAgainDesc: 'Please input the new password again',
    dialog:
      'We have sent an activation email to your new email address, please check it.',
    failedPayment: 'Payment Failed. Please contact our colleagues.',
    emailConfirm: 'Do you confirm to change login email?',
    cannotDeleteRegAddress: 'Cannot delete registration address',
    deleteSuccess: 'Address is successfully deleted',
    delImg: 'Delete',
    clickToView: 'View',
    invoice: 'Invoice',
    receipt: 'Receipt'
  },
  detail: {
    groupByDesc: 'Purchase any products (can be mix-and-match) with {0} cartons or above, Group Buying Price of this product',
    discount: 'Discount',
    groupBuying: 'Group Buying',
    quantity: 'Qty',
    contour: 'Proposed contour types',
    animation: 'Animation',
    guide: 'User Guide',
    outOfStock: 'We will email you when we have the products.',
    stockAmount: 'Qty',
    stockAmountDesc: 'Please input the quantity',
    addSuccess: 'Successfully added',
    addAlready: 'Already in favourite list'
  },
  discount: {
    desc: 'Applicable Product and quantity',
    price: 'Group Buying Price',
    originalPrice: 'Original Price',
    quantity: 'Qty(carton)',
    title: 'Group Buying Discount',
    newDesc: 'Purchase any products (can be mix-and-match) with {0} cartons or above',
    description: {
      smartsec_net: 'Smartsec net {0} cartons or above',
      smartsec_net_seal:
        'Smartsec net {0} cartons or above + Smartsec seal {1} cartons or above'
    },
    description_general: '{0} {1} cartons or above',
    description_general_details: '{0}, Buying Price HK$ {1}',
    type: {
      1: {
        title: 'Group Buying Discount 1',
        quantity:
          'Smartsec net {0} cartons or above + Smartsec seal {1} cartons or above'
      },
      2: {
        title: 'Group Buying Discount 2',
        quantity: 'Smartsec net {0} cartons or above'
      },
      3: {
        title: 'Group Buying Discount 3',
        quantity: 'Smartsec net {0} cartons or above'
      }
    }
  },
  cart: {
    productConfirm: 'Product Confirmation',
    address: 'Delivery Address',
    payment: 'Payment',
    product: 'Product',
    productName: 'Product',
    price: 'Price',
    quantity: 'Qty',
    subtotal: 'Subtotal',
    expensesSubtotal: 'Subtotal',
    total: 'Total',
    chosen: 'Chosen',
    addAddress: 'Add Address',
    saveAddress: 'Save Address',
    fillQuantity: 'Please fill in the qty',
    all: 'Remaining product',
    allProduct: 'All remaining Product(s)',
    partialProduct: 'Partial Product(s)',
    selectProduct: 'Select Product',
    selectFreeProduct: 'Please select the free product(s)',
    freeProduct: 'Product for free',
    rebate: 'Rebate amount used',
    deliveryFee: 'Delivery Fee',
    sameDay: 'Same-day',
    normalDelivery: 'Normal delivery',
    sameDayDelivery: 'Same-day delivery',
    urgentDelivery: 'Urgent delivery',
    pickUp: 'Pick-up',
    sameDayDesc:
      '**Customer must be ordered and completed the payment process before 1:00pm in order to arrange same-day delivery. The additional fee is ${0}. ',
    urgentDesc:
      'The delivery will be arranged within 4 hours after ordering and completing the payment process. The additional fee is ${0}.',
    pickUpDesc: 'Please select a time period for pickup',
    selectDate: 'Select date',
    selectPeriod: 'Select period',
    paymentAmount: 'Payment amount',
    choosePayment: 'Please choose payment method',
    onlinePayment: 'Online Payment',
    offlinePayment: 'Offline Payment',
    onlinePaymentDesc: {
      1: 'Visa and Mastercard credit card payments processed by the Stripe online payment system'
    },
    methods: {
      1: 'Method 1',
      2: 'Method 2'
    },
    dialog: {
      1: 'We have sent an order confirmation email to your registered mailbox, please check it.',
      2: 'If you do not receive the email, please check the "trash" or "spam".',
      3: 'send the email again',
      4: 'Invoice resent already'
    },
    quantityAlert: ' The quantity of gift cannot exceed {0}',
    priceAlert: ' The total value of gift cannot exceed ${0}',
    maxGiftQuantity:
      'Maximum quantity of gift: {0} | selected gift quantity: {1}',
    maxGiftPrice:
      'Maximum total value of gift: ${0} | selected gift value: ${1}',
    noProduct: 'Please select products first.',
    unselectProduct: 'Please confirm the quantity',
    missingOfflinePayType: 'Please choose offline payment type',
    noGift: 'Please select the free product and enter the product quantity.',
    uncompleteAlertMessage: 'The order has not been created, please select a payment method.'
  },
  favorite: {
    title: 'Favorite'
  },
  tab: {
    home: 'Home',
    favorite: 'Favorite',
    cart: 'Cart',
    account: 'Account'
  },
  footer: {
    company: 'Company Profile ',
    contact: 'Contact Us',
    faqs: 'FAQs',
    conditions: 'Terms and Conditions',
    conditions2: 'Terms and<br>Conditions',
    privacy: 'Privacy Policy',
    delivery: 'Delivery and Pick-up Arrangements',
    delivery2: 'Delivery and<br>Pick-up<br>Arrangements',
    exchange: 'Exchange Policy',
    payment: 'Payment Methods',
    payment2: 'Payment<br>Methods'
  },
  about: {
    company: {
      title: 'Company Profile',
      eg1: {
        content1:
          'Hong Kong Secure Transportation Systems Coompany Limited (HKSTS), was established in 2019 to provide a security transportation measures approved by the Civil Aviation Department for the Hong Kong air freight industry.',
        content2:
          'According to the latest policy issued by the International Civil Aviation Organization (ICAO), all known consignors subject to approved by the local Civil Aviation Department must implement 100% cargo security screening by June 30, 2021. In order to ensure that the screened cargo transported from the security warehouse to the Cargo Terminal Operator (CTO) without any unlawful interference or suspicious contact, HKSTS specially designed a secure transportation system "Smartsec System" for the industry. ',
        content3:
          "For the characteristics of Smartsec, having a belt on the Smartsec net to package the cargo more complete, and check the seal with computer rather than visual inspection, which greatly improves the security of cargo. Smartsec net is applicable to a variety of ULD contour models. Automated data processing can improve the accuracy and reduce the workload of data entry. The secure transportation system has also been patented, and the industry can use it with peace of mind. Through simple and fast Smartsec, the secure transportation of Hong Kong's air cargo industry can be improved to the international standards and the competitiveness can also be enhanced."
      }
    },
    contact: {
      1: 'If you have any enquiry regarding the website or your online order or feedback on our online shop, please feel free to contact us. We will do our best to response your enquiry as soon as possible.',
      2: 'Email'
    },
    faqs: {
      title: 'FAQ (Frequently Asked Questions)',
      tips:
        'Please note that the below frequently asked questions are only used as guidance and assistance, therefore, they are not contractually binding and do not change or alter our Website’s Terms and Conditions. ',
      eg1: {
        title: '1. How to contact us?',
        content1:
          'We are happy to help in any way we can. If you have any suggestions for us, please contact our customer service team for assistance.',
        content2: 'Email: '
      },
      eg2: {
        title: '2. How to create a new account?',
        content1:
          'Go to the ‘Login/Register’ page, fill in all the mandatory fields and upload the copies of required document as instructed, then submit the application. We will approve your application within 3 working days. If there is no information missing, we will email you the new account information.'
      },
      eg3: {
        title: '3. How to change my address and other personal details?',
        content1:
          'Please login to ‘My Account’ to change your personal details on your profile. Please note that some of the information are not able to be changed.'
      },
      eg4: {
        title: '4. How do I shop online?',
        content1:
          '4.1 Browse the online shop to view our products and add the selected items to the shopping cart. After completing the purchase, click the "Shopping Cart" to checkout.',
        content2:
          '4.2 Fill in the delivery information, and if needed, please choose whether the order is urgent or not.',
        content3:
          '4.3 After the amount is confirmed, select the payment method and follow the instructions to complete the payment.',
        content4:
          '4.4 After the payment is confirmed, customers will be notified by email with the payment receipt and the details of the delivery. ',
        content5: 'Please refer to the',
        content6: 'Payment Methods',
        content7: 'for details regarding payments.'
      },
      eg5: {
        title: '5. What payment methods are available/ do you accept',
        content1: 'The payment methods can be chosen:',
        content2: '(i) FPS or Bank in deposit/transfer - bank counter, ATM, e-banking',
        content3: '(ii) Visa and Mastercard credit card payments processed by the Stripe online payment system',
        content4:
          '',
        content5: 'Please note that we do not accept the cash payment.',
        content6: 'Please refer to the',
        content7: 'Payment Methods ',
        content8: 'for details regarding payments.'
      },
      eg6: {
        title:
          '6. What security checks are in place to make sure my purchase is safe and valid?',
        content1:
          'All credit cardholders are subject to authorization by the credit card company. If the credit card company refuses to authorise payment for any reason, we will not be able to accept your order. '
      },
      eg7: {
        title: '7. Can I pay in other currency?',
        content1:
          'HKSTS products are priced in Hong Kong dollars and therefore we only accept payments in Hong Kong dollars.'
      },
      eg8: {
        title: '8. Can I order over phone?',
        content1:
          'Ordering over phone is not yet accepted right now. Please go to our online shop to make a purchase.'
      },
      eg9: {
        title: '9. I am having problems submitting an order.',
        content1:
          'Please try clearing the cookies and caches on your browser. Then, log back into your account. ',
        content2:
          'If further problems persist please note the message of errors you are getting and contact our customer service team. We will be glad to take your order and help to solve the issue.'
      },
      eg10: {
        title: '10. How is the transportation fee in my order calculated?',
        content1:
          'The calculation of transportation fees consists of two parts:',
        content2:
          'The first part are the basic delivery fee (including road / tunnel / bridge tolls), which will be displayed on the invoice and will be paid together when customer pays the invoice.',
        content3:
          'The second part is the additional delivery fees, which will be calculated on a per-order basis according to the actual situation, and need to be paid by the buyer directly in cash in a reimbursable manner to the delivery driver. This part will not be displayed on the invoice. Additional delivery fees include but are not limited to entry fee, registration fee, parking lot fee, overtime waiting charge, etc.',
        content4: 'Please refer to the',
        content5: 'Delivery Arrangements',
        content6:
          'for details regarding transportation fees and other possible cost arrangements.'
      },
      eg11: {
        title: '11. Can I pick up the products myself',
        content1: 'Yes, please refer to the',
        content2: 'Pick-up Arrangements',
        content3: 'for details and procedures. '
      },
      eg12: {
        title:
          '12. Will I receive notification once my order has been submitted',
        content1:
          'Yes. After submitting your order, we will send you an email with a copy of the invoice, order and payment information. Once you have completed the payment, you will receive an email notification that the payment has been completed and delivery arrangements.'
      },
      eg13: {
        title: '13. Will I be notified when my order is ready?',
        content1:
          'Yes. Once you have completed the payment, you will receive an email notification that the payment has been completed and delivery and pick-up arrangements.'
      },
      eg14: {
        title: '14. Can I cancel or change my order?',
        content1:
          'Orders cannot be cancelled or changed after payment process has been completed.'
      },
      eg15: {
        title: '15. How to exchange my products?',
        content1: 'Exchange Conditions',
        content2:
          'The product to be exchanged must meet the following requirements, otherwise, the exchange application will not be accepted:',
        content3:
          '1. The product must be exchanged within 7 days after receipt.',
        content4:
          '2. Before using, the product is found to have any damage caused by non-human factors, quality problems, or loose or missing parts.',
        content5: '3. The product must be unused.',
        content6:
          '4. The product must be kept in good condition, intact and retain the original box and accessories.',
        content7: '5.  Product must be purchased on our Website.',
        content8: 'Exchange Procedures',
        content9:
          'If you would like to exchange the product, please make sure the product(s) meets the requirements of exchange conditions, and follow the exchange procedures to process:',
        content10:
          '1. Customers are required to submit the exchanging request by email at enquiry@hksts.com.hk within 7 days from the date of receipt. Please indicate the email with "Exchanging Request" and the order number.  In the email, please provide a screenshot of the order confirmation email, information on the replacement of the product, reasons for exchanging, relevant photo and contact information. If the information provided by the customer is partly missing, this will affect the time of exchange.',
        content11:
          '2. After receiving our confirmation email, we will contact you for the arrangement of returning the product.',
        content12:
          '3. After receiving the returned product, we will confirm whether the products are in compliance with the corresponding requirements of the Exchange Conditions. We will then inform you the status of application as soon as possible.',
        content13:
          '4. After confirming the returning method with you, you must complete the returning process within 7 days. Otherwise, you will be deemed to have waived the request and have no right to claim from the company.',
        content14:
          '5. When the above procedure is completed, we will arrange the delivery according to the delivery address of your order, and will be completed within 14 days. This transportation fee will be borne by the manufacturer, but it does not include the additional transportation fees (e.g. overtime waiting charge). If the product is out-of-stock, we must wait for the product arrived before arrange the delivery again, the time required may exceed 14 days, we will not be liable for any direct, indirect, special or consequential loss.',
        content15: 'Transportation Fee of Exchanges',
        content16:
          'If you need to exchange the product in accordance with the exchange conditions, we will bear the basic transportation fee of the product, but does not include other additional transportation fees.',
        content17: 'Please refer to the',
        content18: 'Delivery Arrangements',
        content19: ' for details regarding transportation fees.'
      },
      eg16: {
        title:
          '16. What should I do if there is something wrong with my order?',
        content1:
          'If there is something wrong with your order, please email us at(',
        content2: ').'
      },
      eg17: {
        title: '17. Can I check my previous order through the website?',
        content1:
          "Yes, please login to 'My Account' and check your purchase history."
      },
      eg18: {
        title: '18. Can I check the progress of orders through the website?',
        content1:
          "Yes, please login to 'My Account' and check your current order status."
      },
      eg19: {
        title: '19. Can you provide the quotation?',
        content1:
          'Price of product is subject to the online shop price and we are unable to provide quotation at the moment, thank you for your understanding.'
      },
      eg20: {
        title: '20. How to check the inventory of products?',
        content1:
          'Our inventory quantity is updated in real time. You will be able to order the quantity on online shop up to the maximum inventory available. If the quantities you are looking for are not available, please contact our customer service team for assistance.'
      },
      eg21: {
        title: '21. Can I order out-of-stock products?',
        content1:
          'Out-of-stock products are not available for ordering, but customers can leave an email address. When the product is replenished, we will notify you by email that the product has been replenished.'
      },
      eg22: {
        title: '22. How do you keep my personal information secure?',
        content1:
          'We take your privacy and security very seriously. We work hard to make sure that we only use your information in the ways that you want us to. We will collect, use or disclose your Personal Data only with your knowledge and consent unless otherwise required or permitted by law. Please read our',
        content2: 'Privacy Policy ',
        content3: 'for further details.'
      },
      eg23: {
        title: '23. How safe is shopping on our website?',
        content1:
          'We realize how important it is to securely store any information that you provide. HKSTS is responsible for ensuring your Personal Data is protected. We take your privacy seriously. We have implemented security safeguards appropriate to the sensitivity of the information, including the use of the following measures:',
        content2:
          '(i) Only authorised employees are allowed to access Personal Data and their use are limited to the extent permitted to comply with established policies and guidelines; and',
        content3:
          '(ii) Using electronic security measures  to access Personal Data, such as password protection. '
      }
    },
    conditions: {
      title: 'Terms and Conditions',
      tips1: {
        content1: 'Welcome to',
        content2:
          '("Website") operated by Hong Kong Secure Transportation Systems Limited ("We/us", "Our" and "HKSTS").',
        content3:
          'Please read carefully the following Terms and Conditions, which govern your use of the Website and/or the information contained therein.'
      },
      tips2:
        'By accessing the Website, you agree to be bound by the Terms and Conditions as well as accepting the "Personal Data (Privacy) Policy Statement" ("Statement") posted on the Website. We reserve the right to change the Terms and Conditions from time to time without notice to you. Your continued use of the Website following such revision shall be deemed as your acceptance of the amended the Terms and Conditions. If at any time you do not agree to the Terms and Conditions, please do not use the Website.',
      tips3:
        'The Terms and Conditions are written in both English and Chinese. In the event of any discrepancy or inconsistency between the English version and the Chinese version, the Chinese version shall prevail.',
      eg1: {
        title: '1. Use of the Website',
        content1:
          '1.1 The Website may be used only for lawful purposes and in a lawful manner. You agree to comply with all applicable laws and regulations regarding the Website.',
        content2:
          '1.2 You are prohibited from violating or attempting to violate the security of the Website, including without limitation accessing data not intended for you, logging into a server which you are not authorised to access, probing, scanning or testing the vulnerability of a system or network utilized by the Website, breaching the security or authentication measures adopted by us for the Website and interfering with the access to or use of the Website by other users.',
        content3:
          '1.3 You shall not misuse the Website by knowingly introducing viruses, Trojans, worms, logic bombs or any other materials which are malicious or technologically harmful, and shall not attack the Website in any manner whatsoever, including without limitation via denial-of-service attack or distributed denial-of-service attack.',
        content4:
          '1.4 In the event that you have breached or violated, or we suspect that you have breached or violated or attempt to breach or violate, any provisions of the Terms and Conditions or any applicable laws and regulations, we shall have the absolute right to expel you and prevent your further access to the Website (by way of, including but not limited to, blocking your Internet Protocol (IP) address) without notice and compensation of any kind to you.',
        content5:
          '1.5 Your breach or attempted breach of the Terms and Conditions, including without limitation this paragraph [1], will subject you to civil liability and possibly also criminal liability, whereupon we shall take legal actions against you as we deem necessary and expedient, including without limitation reporting to the relevant law enforcement authority and disclosing your identity to them.'
      },
      eg2: {
        title: '2. Intellectual Property Rights',
        content1:
          '2.1 All contents on the Website (including without limitation text, designs, pictures, graphics, logos, icons, images, layout, brands, illustrations, audio and video clips, interfaces, codes, software, the compilations, selections and arrangements thereof and other proprietary materials contained on the Website) are the exclusive properties of ours, and are protected by copyright, trademark, service mark and/or other proprietary laws.',
        content2:
          '2.2 You may access, copy, download and print the materials contained on the Website for your personal non-commercial use only, provided, however, you will not modify or delete any copyright, trademark or other proprietary notices in such materials in whatsoever manner. You will not obtain any right, title or interest in any of such materials as a result of such access, copying, downloading or printing.',
        content3:
          '2.3 We have a no-tolerance policy regarding the use of our Trademarks. You must not reproduce, transmit, adapt, distribute, sell, modify, publish, or store Content or Trademarks obtained from the Website (in whole or in part) for any purpose, or use of the Website for purposes competitive to the businesses of ours, other than with the prior written consent of us, or as permitted by law. All rights of us are expressly reserved. '
      },
      eg3: {
        title: '3. Contents of the Website ',
        content1:
          '3.1 We give no warranty as to the accuracy, completeness, timeliness, utility or other qualities of the information contained on or given in relation to the Website, and shall not be liable for damage of any kind (whether we ought reasonably to have known or had been advised on the possibility of the same) arising from and/or in connection with the information contained on the Website. In no event shall you rely on any of the materials on the Website as the sole basis for making decisions without consulting primary and more accurate, complete and timely sources of information. Any reliance on the materials on the Website is entirely at your own risk.',
        content2:
          '3.2 Any typographical error, clerical or other error or omission on the Website shall be subject to our correction at any time without any liability on our part.',
        content3:
          '3.3 We reserve the right to modify or withdraw, temporarily or permanently, the Website (or any part thereof) with or without notice to you. In any event, we shall not be liable to you for any modification to or withdrawal of the Website.'
      },
      eg4: {
        title: '4. Communication and Submission',
        content1:
          '4.1 You agree and consent that any and all comments, messages, data, suggestions, designs, concepts and other items or materials disclosed, submitted or offered to us through or in connection with the Website (collectively "Submission") are of non-confidential basis. We shall be under no obligation to (i) maintain any Submission in confidence (save in relation to the foregoing excepted Submission); (ii) pay to you or anyone any compensation for or in connection with the use of any Submission; or (iii) respond to any Submission.',
        content2:
          '4.2 You represent and warrant that no Submission by you (include but not limited to)',
        content3: '(i) violates any applicable laws or regulations.',
        content4:
          '(ii) makes any speculative, false or fraudulent orders (failure to do so will lead to the cancellation of your order and our entitlement to inform relevant authorities as we see fit). ',
        content5:
          '(iii) infringes the copyright, trademark, trade secrets or other intellectual property rights of others or violates the privacy or publicity or other personal rights of others.',
        content6:
          '(iv) is libelous, defamatory, obscene, pornographic, scandalous, threatening, abusive or hateful.',
        content7:
          '(v) advertises or solicits funds, goods, services, or disseminates any junk mails, spam, chain letters, schemes or other forms of solicitation (unless the nature of the Website requires such activities).',
        content8:
          "(vi) contains viruses, Trojan horses, worms, corrupted files, or other materials that may cause damage to another's computer, or ",
        content9:
          '(vii) uses a false email address, impersonate any person or entity, or otherwise mislead us as to the origin of any Submission.'
      },
      eg5: {
        title: '5. Link to other websites',
        content1:
          'Where the Website provides links to other websites which are operated by any other third parties, these are provided solely as a convenience to you, and shall not in any way be regarded as an endorsement of any kind by us approving the contents on such websites. If you access any of such websites, you do so entirely at your own risk and are subject to the Terms and Conditions applicable thereto. We neither take any responsibility for nor make any representations, warranties or undertakings (whether express or implied) as to their availability and the contents contained therein. We shall not be responsible for any loss, damage or other liability incurred as a result of your use of any of such websites. Any questions or comments relating to such websites shall be addressed to the operators and/or proprietors of such websites.'
      },
      eg6: {
        title: '6. Disclaimer and Limitation of Liability',
        content1:
          '6.1  Given the unpredictability of technology and the online environment, we do not warrant (whether expressly or impliedly) that the function, operation or accessibility of the Website will be uninterrupted or error-free, that defects will be corrected, or that the Website or the server that makes it available will be free of viruses, Trojan horses, worms or other harmful elements. It is your responsibility to ensure that the right equipment is available to you in order to properly access and use the Website and screen out anything that may damage your computer. We accept no liability whatsoever and howsoever arising from and/or in connection with your use of or inability to access the Website.',
        content2:
          '6.2  The Website and its contents are provided on an "as is" basis at your own risk without any warranties of any kind. To the fullest extent permissible under applicable laws and regulations, we disclaim any and all warranties of any kind, whether express or implied, in relation to the Website and the information contained therein.',
        content3:
          '6.3  We shall not be liable for any failure, delay or default in carrying out our obligations or duties hereunder directly or indirectly caused by or resulted from acts of God, fire, flood, accident, epidemic, riot, war, terrorist attack, government intervention, embargoes, strikes, labour disputes, equipment failures (including but not limited to Internet system failure), or any other causes beyond our reasonable control.',
        content4:
          '6.4  Notwithstanding anything to the contrary in these Terms and Conditions, our liability for losses you suffer arising under or in connection with our breach of these Terms and Conditions (whether in contract, tort or otherwise) is limited to the purchase price of the product you purchased and any losses which are a foreseeable consequence of us breaking this agreement. Losses are foreseeable where they could be contemplated by you and us at the time your order is accepted by us.',
        content5:
          '6.5  To the extent permitted by law, we hereby expressly exclude all liability (howsoever arising) in connection with any loss, damage and/or expense arising out of or in connection with use of the Website. In particular and without limitation to the generality of the above, we will not be liable for any loss or damage to computer equipment, software or data, any financial loss, indirect, special or consequential loss or damage.',
        content6:
          '6.6  According to the data obtained by the our repeated tests on the Smartsec nets provided on the Website, if you follow the instructions on using the Smartsec nets, the net can be reusable. In case of any wilful damage, accident, negligence by you or any third party, use otherwise than in accordance with our instructions, or any alteration or repair carried out without our approval., the net may not be reused. We will not assume any responsibility.',
        content7:
          '6.7  For individual customer’s order with large quantity, it may exceed our inventory. We have to place order with the manufacturer. The time for manufacturing and for shipping to Hong Kong is about 40-60 days (please note that this is only an estimate, the time must be determined according to the actual situation.). When the products arrive at the warehouse in Hong Kong, it needs to take time to deliver to customers. If you still choose to purchase, that means you know and understand the time required. We will not be liable for any direct, indirect, special or consequential loss.'
      },
      eg7: {
        title: '7.  Our Products',
        content1:
          '7.1  In describing the products available to purchase online, we attempts to be as accurate as possible. However, we does not warrant that product descriptions or other contents of any our services are accurate, complete, reliable, current or error-free.',
        content2:
          '7.2  Please note that the weights, measurements, sizes dimensions and capacities shown on the Website are provided as best estimates. There may be variations in the manufacturing processes, meaning that products delivered may have minor differences in appearance to how they are displayed and described on the Website. ',
        content3:
          '7.3  Notwithstanding anything to the contrary in these Terms and Conditions, all products supplied by us are warranted to be of satisfactory quality and reasonably fit for purpose free from defects in workmanship or materials for a period as shown on the Website. This warranty does not affect your statutory rights as a consumer. ',
        content4:
          '7.4  This warranty is only valid with proof of purchase from us and does not apply to any defect in the products arising from fair wear and tear, wilful damage, accident, negligence by you or any third party, use otherwise than in accordance with our instructions, or any alteration or repair carried out without our approval.',
        content5:
          '7.5  Downloaded material is provided "as is" and your use of the downloaded content is at your own risk. Downloads may contain significant or insignificant program errors, bugs, and viruses, which may cause an operational interruption to your computer system. You must screen the download for viruses before access.',
        content6:
          '7.6  Except to the extent that such terms cannot be limited or excluded by law, no conditions, warranties, representations or other terms, whether express or implied shall apply with respect to any download.'
      },
      eg8: {
        title: '8. Availability of Items',
        content1:
          'All orders for items are subject to availability. In the event the items are no longer in stock or we experience supply difficulties, you must wait until the stock is replenished before you can create an order. Product replenishment time will be based on the manufacturing and shipping time of supplier, which will exceed our promised delivery time. We will not be liable for any direct, indirect, special or consequential loss.'
      },
      eg9: {
        title: '9. Pricing',
        content1:
          '9.1 All prices and charges on this Website are in Hong Kong Dollars (HK$). ',
        content2:
          '9.2 We uses all reasonable efforts to ensure that all pricing information on the Website is correct and up-to-date, but errors (including processing errors) may occur. Please note that we are under no obligation to provide the items to you at the incorrect (lower) price. ',
        content3:
          '9.3 The price of the product does not include delivery fees or expenses incurred by pick-up.',
        content4:
          'Delivery fees will be calculated separately and displayed on the invoice. Please note that the delivery fees on the invoice (including road / tunnel / bridge tolls) will be calculated according to the standards listed on the page of Delivery Arrangements. Other possible miscellaneous expenses, including but not limited to those listed in paragraph [9.4], need to be paid by the buyer directly in cash in a reimbursable manner to the delivery driver. We will not be liable for this behavior.',
        content5:
          'The expenses incurred by pick-up will not be shown on the invoice. This fee includes but not limited to the items listed in item 9.5, and will be paid directly by the buyer in cash. We will not be liable for this behavior.',
        content6:
          '9.4 For delivery arrangement, the transportation fee will be calculated and clearly displayed during the order process. The total transaction amount will be shown in the invoice and payment receipt. But the amount does not include other possible miscellaneous fees (include but not limited to):',
        content7: '(i) entry fee',
        content8: '(ii) registration fee',
        content9: '(iii) parking lot fee',
        content10: '(iv) overtime waiting charge',
        content11:
          'The above miscellaneous fees (include but not limited to) will be calculated on a per-order basis according to the actual situation, and need to be paid by the buyer directly in cash in a reimbursable manner to the delivery driver. If the buyer does not pay the above fees, the driver will refuse the delivery and all losses incurred will be deducted from the paid fees.',
        content12:
          'For pick-up arrangement, the buyer will pick up the relevant product in the warehouse and pay the miscellaneous fees in cash, including but not limited to:',
        content13: '(i) Entrance fee',
        content14: '(ii) Registration fee',
        content15:
          'The above miscellaneous fees are calculated and reimbursed for each order and paid directly by the buyer in cash. If the buyer does not pay the above fees,  the products will not be released and we will not be liable for this behavior.',
        content16: 'Please refer to the',
        content17: 'Delivery and Pick-up Arrangements',
        content18:
          'for details regarding transportation fee and other miscellaneous fees.'
      },
      eg10: {
        title: '10. Payment Methods',
        content1: 'Please refer to the',
        content2: 'Payment Methods',
        content3: 'for details regarding payments.'
      },
      eg11: {
        title: '11. Delivery Arrangements',
        content1:
          '11.1 If our supply of the items is delayed by event outside our control, we will contact you as soon as possible to let you know, and we will take steps to minimise the effect of the delay. Please note that we will not be liable for delays caused by the event. ',
        content2:
          '11.2 If the buyer is not at the delivery location at the time agreed, the buyer may have to bear the additional fees (please refer to the Delivery Arrangements), and these fees will be calculated on a per-order basis according to the actual situation. The buyer needs to pay directly in cash in a reimbursable manner to the delivery driver. We will not be liable for this behavior.',
        content3: '11.3 Please refer to the',
        content4: 'Delivery Arrangements',
        content5: 'for details regarding delivery.'
      },
      eg12: {
        title: '12. Pick-up Arrangements',
        content1:
          '12.1 If the buyer does not pick-up the products at the time agreed, the buyer may have to bear the additional fees, and these fees will be calculated on a per-order basis according to the actual situation. The buyer needs to pay directly in cash in a reimbursable manner to the warehouse. We will not be liable for this behavior.',
        content2: '12.2 Please refer to the',
        content3: 'Pick-up Arrangements',
        content4: 'for details regarding product pick-up. '
      },
      eg13: {
        title: '13. Exchange Arrangements',
        content1: 'Please refer to the',
        content2: 'Exchange Policy',
        content3: 'for details regarding exchanges.'
      },
      eg14: {
        title: '14. Risk of Loss and Title to Goods',
        content1:
          '14.1 All items purchased from the Website are made pursuant to a shipment contract. This means that the risk of loss and title for such items pass to you upon our delivery to the carrier. ',
        content2:
          '14.2 Ownership of the items will only pass to you when we receive full payment of all sums due in respect of the items, including transportation fees, or upon delivery to the carrier, whichever is later. '
      },
      eg15: {
        title: '15. Changes to These Terms and Conditions',
        content1:
          '15.1 We reserves the right, at our sole discretion, to change, modify, add or delete any part of these Terms and Conditions at any time without further notice to you. Any changes to the Terms and Conditions will be posted on the Website and the date at the bottom of these Terms and Conditions will be amended to show when these Terms and Conditions were last revised. We recommends that you revisit these Terms and Conditions regularly to keep informed of the current terms that apply to your use of the Website and the delivery of your products.',
        content2:
          '15.2 Your continued access and use of the Website after any changes or updates constitutes your acceptance of the new Terms and Conditions. It is your responsibility to regularly check the Website and determine if there have been any changes to these Terms and Conditions.',
        content3:
          '15.3 If any of these Terms and Conditions or any provisions of the Contract are determined by an competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.',
        content4:
          '15.4 These Terms and Conditions shall not be altered or modified in any way without the prior written consent of us.。'
      },
      eg16: {
        title: '16. Indemnity ',
        content1:
          'You irrevocably and unconditionally agree to defend, indemnify and hold harmless us, our officers, directors, agents, employees, contractors, licensees, other representatives and Affiliates from and against all claims, actions, demands, liabilities, expenses, losses, damages and other costs whatsoever arising from and/or in connection with your use of the Website or breach of the Terms and Conditions.'
      },
      eg17: {
        title: '17. Privacy Policy',
        content1:
          'We respect your privacy and are committed to protect your personal information and data. Please refer to the ',
        content2: 'Statement',
        content3: 'for details.'
      },
      eg18: {
        title: '18. Entire Agreement',
        content:
          'The Terms and Conditions shall constitute the entire agreement between you and us relating to your use of the Website, and shall replace and supersede all other communications (written or oral), discussions, letters and contracts relating to the subject matter hereof.'
      },
      eg19: {
        title: '19. Assignment',
        content:
          'You shall not assign or transfer any of your rights or obligations under the Terms and Conditions without our prior written consent.'
      },
      eg20: {
        title: '20. Waiver',
        content:
          'No failure or delay by us in exercising any right, power or remedy under the Terms and Conditions shall operate as a waiver thereof, nor shall any single or partial exercise of the same preclude any further exercise thereof or the exercise of any other right, power or remedy. Without limiting the foregoing, no waiver by us of any of your breach of any provision of the Terms and Conditions shall be deemed as a waiver of any subsequent breach of that or any other provision herein.'
      },
      eg21: {
        title: '21. Severability',
        content:
          'The provisions contained in each clause of the Terms and Conditions shall be enforceable independently of each of the others, and its validity shall not be affected if any of the others is invalid. In the event that any provision of the Terms and Conditions is determined to be illegal, invalid or unenforceable, the validity and enforceability of the remaining provisions of the Terms and Conditions shall not be affected and, in lieu of such illegal, invalid or unenforceable provision, there shall be added as part of the Terms and Conditions one or more provisions as similar in terms as may be legal, valid and enforceable under the applicable laws.'
      },
      eg22: {
        title: '22. Governing Law and Dispute Resolution ',
        content:
          'The Terms and Conditions shall be governed by and construed in accordance with the laws of the Hong Kong Special Administrative Region of the People\'s Republic of China ("HKSAR"). You irrevocably agree that the courts of the HKSAR shall have exclusive jurisdiction in relation to any claim or dispute arising out of or in connection with the Terms and Conditions or the breach, termination or invalidity thereof.'
      },
      eg23: {
        title: '23. Enquiries ',
        content1:
          'If you have any enquiries relating to the Website, please email us at',
        content2:
          ' . While we endeavour to respond to each and every enquiry that you make as instantly as practicable, we shall not be responsible and/or liable for any delayed response or omission to respond.'
      }
    },
    privacy: {
      title: 'Privacy Policy',
      tips: 'PERSONAL DATA (PRIVACY) POLICY STATEMENT ("STATEMENT")',
      eg1: {
        title: '1. Collection Policy and Objectives',
        content1:
          'We, Hong Kong Secure Transportation Systems Limited (香港安全運輸系統有限公司), pledge to meet fully with the requirements of the Personal Data (Privacy) Ordinance, Chapter 486 of the Laws of Hong Kong. In doing so, we will ensure compliance by our staff to the strictest standards of security and confidentiality.',
        content2:
          'You are strongly recommended to read the Statement carefully to have an understanding of our policy and practices with regard to the treatment of personal information and data provided by you to us at',
        content3:
          ' ("Website"). By using the Website, you signify your agreement to the collection, use and disclosure of your personal data by us in accordance with the Statement.',
        content4:
          'Please note that the provisions herein may be updated, revised, varied and amended from time to time as we deem necessary. Your continued use of the Website following such revision shall be deemed as your acceptance of the amended Statement. It is your responsibility to check the Statement regularly for any amendments.',
        content5:
          'The Statement is written in both English and Chinese. In the event of any discrepancy or inconsistency between the English and Chinese version of the Statement, the Chinese version shall prevail.'
      },
      eg2: {
        title: '2. Purposes of Collection of Personal Data',
        content1:
          'We may ask you to provide Personal Data when you use the Website, including when you voluntarily register or update your existing profile with us, email us, use our customer services, enter promotional opportunities, or make any other submissions to our Website. ',
        content2:
          'Please note that when registering as a user and using this Website, you will be asked to provide us with personally identifiable information, including name, email address, delivery/invoice address, phone number, mobile phone number, company name, copy of business registration, copy of the company registration certificate*, etc. And we will treat you as a legal provider of the information, you represent and warrant that no submission by you (include but not limited to)',
        content3: '(i) violates any applicable laws or regulations,',
        content4:
          '(ii) infringes the copyright, trade secrets or other intellectual property rights of others or violates the privacy or other personal rights of others. All legal issues and responsibilities arising from the information submitted by you must be borne by you (i.e. legal provider), and we will not be liable for this.',
        content5:
          'It is necessary for us to collect these information in order to provide users with the services and activities of this Website. If the user fails to provide the required information, we cannot be able to provide users with services and activities available on our Website.',
        content6:
          '* Uploading these copies are for the security reasons, to ensure that the applicant company fulfills all the requirements of the Business Registration Ordinance and the Companies Ordinance, and uses to verify the user information.',
        content7:
          'Our purposes for collection of the information and data on the Website include but not limited to the following:',
        content8:
          '2.1 to process orders and provide you with various services offered by us, online shopping, marketing materials, event information and promotional messages.',
        content9:
          '2.2 to handle and follow up on service calls, enquiries and complaints.',
        content10:
          '2.3 to contact you regarding administrative notices and communications relevant to your use of the Website and/or the services offered by us.',
        content11: '2.4 to verify your identity.',
        content12:
          '2.5 to obtain statistics about our users to analyse website usage in order to help its further development.',
        content13:
          '2.6 to better understand your needs and provide customised service to you.',
        content14:
          '2.7 to evaluate the effectiveness of our advertising and marketing campaigns, and',
        content15:
          '2.8 to send direct marketing emails for apparel products and services.',
        content16:
          'You have the right to opt out of receiving promotional communications at any time by contacting us via the contact channels set out in this Policy.'
      },
      eg3: {
        title: '3. Collection of Personal Data from Users Visiting the Website',
        content1:
          'When you access the Website, we will record your domain name server address and/or email address and track the pages you visit and store such information in "cookies". This is done without you being aware that it is occurring. Generally, we use "cookies" to estimate our audience size, gauge the popularity of various parts of the Website, track users" progress and number of entries in our promotional activities and to measure users traffic patterns. Information and data collected by means of "cookies" will not be disclosed save and except for the circumstances listed out in paragraph.'
      },
      eg4: {
        title: '4.  Disclosure of Personal Data',
        content1:
          'We agree to take all practicable steps to keep all your personal information and data confidential and/or undisclosed save and except under the following circumstances whereby the said information and data may be disclosed and/or transferred:',
        content2:
          '4.1 to any third party suppliers or service providers who have been duly authorised by us to use the said information and/or data and who will facilitate our services.',
        content3:
          '4.2 to marketing agents, consultants, affiliates or associates of us who have been duly authorised by us to use the said information and/or data.',
        content4:
          '4.3 where we need to protect and defend our rights and property.',
        content5:
          '4.4 where we consider we are required to do so in order to comply with the applicable laws and regulations, and',
        content6:
          '4.5 where we deem necessary in order to maintain and improve our services.',
        content7:
          'By using the Website, you are deemed to have agreed to, consented to and authorised us to disclose and transfer your personal information and/or data under the circumstances stated above.'
      },
      eg5: {
        title: '5. Links to Other Websites',
        content1:
          'To provide you with more information, the Website may provide links to other websites, including without limitation certain social media websites (such as Facebook). Personal information and data from you may be collected on these other websites when you visit such websites and make use of the services provided therein. Where and when you decide to click on any advertisement or hyperlink on the Website which grants you access to another website, the protection of your personal information and data which are deemed to be private and confidential may be exposed in these other websites.',
        content2:
          'The Statement is only applicable to our Website. You are reminded that the Statement grants no protection to your personal information and data that may be exposed on websites other than our Website. Where necessary, you may adjust the privacy and/or security setting in your computer/browser with respect to those other websites. To better protect your privacy rights, you are strongly recommended to refer to the privacy policy of such other websites. We assume no responsibility or liability for your use of such other websites.'
      },
      eg6: {
        title: '6. Security',
        content1:
          'We take reasonable steps to make sure that the Personal Data we collect is protected from unauthorised access, disclosure, misuse or loss. We will collect, use or disclose your Personal Data only with your knowledge and consent, as set out in the Privacy Policy unless otherwise required or permitted by law.',
        content2:
          'We are responsible for ensuring your Personal Data is protected. We take your privacy seriously. We have implemented security safeguards appropriate to the sensitivity of the information, including the use of the following measures:',
        content3:
          '(i) Only authorised employees are allowed to access Personal Data and their use are limited to the extent permitted to comply with established policies and guidelines; and',
        content4:
          '(ii) Using electronic security measures to access Personal Data, such as password protection.',
        content5:
          'Please rest assured that your personal information and data will only be kept for as long as is necessary to fulfill the purpose for which it is collected as mentioned in paragraph [2] above, unless you request us to cease to hold the same. Such request may render us unable to further provide our services, information and/or promotional materials for your benefit in the future.'
      },
      eg7: {
        title: '7. Marketing Materials',
        content:
          'We may from time to time use email to communicate marketing and promotional materials and information regarding our products, services and the like based on the personal information and data that you have provided to us. If you do not wish to receive further marketing and promotional materials and information from us, you can make a request of not receiving such information by contacting us via the contact channels set out in this Policy.'
      },
      eg8: {
        title: '8. Accessing Data',
        content1:
          '8.1 You have the right of access to or correction of, and to withdraw your consent concerning your Personal Data held by us.',
        content2:
          '8.2 If you have any questions regarding this Privacy Policy, or if you wish to access or change your Personal Data, or withhold the information from us, you may visit your “My Account” page to edit or update your Personal Data, or send your request to the following:',
        content3: 'Email: ',
        content4:
          '8.3 We reserve the right to charge you a reasonable fee for the processing of any data access request. Upon receiving the request(s) from you, we may request you to provide personal details in order to verify your identity. We will endeavour to respond to your request(s) within 30 days after receiving the same wherever possible.'
      },
      eg9: {
        title: '9. Cookies Policy',
        content1:
          'Cookies are small data files placed on your computer or mobile device by websites like this to collect information about your use of the website, ‘remember’ you when you visit, and tailor products, services and advertisements to you both on and off the website. Cookies are often essential to the functionality of a website.',
        content2:
          'Some cookies collect information about browsing and purchasing behaviour when you access the Website via the same computer or device.  This includes information about pages viewed, products purchased and your journey around a website. We may use cookies to monitor your browsing and purchasing behaviour. However, we will not use cookies to collect or record information on your name, address or other contact details.  ',
        content3: 'The main reasons of using cookies are:',
        content4:
          '9.1 for technical purposes essential to the effective operation of our Websites, particularly in relation to online transactions and site navigation.',
        content5:
          '9.2 to enable us to market to you, particularly web banner advertisements and targeted updates.',
        content6:
          '9.3 to enable us to collect information about your browsing and shopping patterns, including monitoring the success of a campaign.',
        content7:
          'If you want to disable cookies, you need to change your website browser settings.  How you do this will depend on the browser you use. Generally, the website may not operate properly if cookies are switched off.  If you only disable third party cookies, you will still be able to make purchases on our Website.  If you disable all cookies, you will be unable to complete a purchase on our Website.'
      },
      eg10: {
        title: '10. Enquiries',
        content: 'Should you have any queries, please email us at '
      }
    },
    delivery: {
      title: 'Delivery Arrangements',
      eg1: {
        title: '1. Delivery Instructions',
        content1:
          '1.1 The delivery service provided by us is charged separately.',
        content2:
          '1.2 The driver is only responsible for products transportation and drop-off, but does not include carrying the products. Customer needs to handle the products themselves after the products drop-off.',
        content3:
          '1.3 If there is road closure in the delivery area due to large-scale public events, such as celebrations, demonstrations, etc., the delivery time may be re-arranged. We will contact customer for the new arrangement.',
        content4:
          '1.4 The delivery team will contact customer regarding the estimate arrival time.',
        content5:
          '1.5 Rainstorm Warning Signal and Typhoon Signal Delivery Arrangements:',
        content6:
          '(i) Yellow, Red Rainstorm Warning Signal or Typhoon Signal No. 3 or below : Delivery service is normally maintained, but there may be delays due to deterioration of road conditions.',
        content7:
          '(ii) Black Rainstorm Warning Signal, Typhoon Signal No. 8 or above, or vehicle suspension due to weather, severe flooding, road obstruction or closure: delivery service will be suspended and delivery date and time needs to be re-arranged.',
        content8:
          '1.6 Upon receipt of product, we require your signature representing that responsibilities of purchased products have been transferred to you. If you have specified a recipient who is not you, then you accept that the signature by recipient is an evidence of delivery and fulfillment by HKSTS.',
        content9:
          '1.7 We reserves the right to make any changes to the terms of the Delivery Arrangements without prior notice and to retain the final decision on the transaction. In the event of any discrepancy or inconsistency between the English version and the Chinese version, the Chinese version shall prevail.'
      },
      eg2: {
        title: '2. Delivery Range and Time',
        content1:
          '2.1 All delivery services are calculated on per-order basis and limited to a single delivery location.',
        content2:
          '2.2 The scope of delivery only covers all major industrial and commercial areas in the area of New Territories, Kowloon and Airport, and there must be a drop-off area. Delivery to other area or the non-industrial and commercial areas are subject to extra fees.',
        content3:
          '2.3 Delivery will be scheduled on every Wednesday and Saturday (except public holidays **), and the order cut-off time will be 2:00pm the day before the delivery. As long as the payment is completed at 2:00pm the day before the delivery, we will arrange the delivery on the nearest delivery day (Wednesday or Saturday). As the service time will vary depending on different region, the delivery date and time cannot be designated.',
        content4:
          '**Please note: If the delivery date is public holiday, the delivery will be arranged on the previous day or the relevant customer will be notified of the delivery date, and the cut-off time will be 2:00pm the day before the delivery.'
      },
      eg3: {
        title: '3. Delivery Fees',
        content1:
          'The price of the products does not include the delivery fees and additional delivery fees, and the delivery fees (including road / tunnel / bridge tolls) will be calculated according to the following standards and will be displayed on the invoice.',
        content2:
          'The delivery fees (include normal delivery fee and same-day delivery fee) are calculated per-order basis and limited to single delivery location.',
        content3: '(i) Normal delivery fee:',
        content4: {
          title1: 'Area',
          title2: 'Fee (per location)',
          title3: 'Remarks',
          type1: 'Kowloon area',
          type2: 'New Territories area',
          type3: 'Airport area',
          price1: '$250.00',
          price2: '$250.00',
          price3: '$320.00',
          remarks:
            'The upper limit includes the delivery of 16 boxes product, followed by an additional $10.00 per box'
        },
        content5: '(ii) Same-day delivery fee : (optional)',
        content6: 'For same-day delivery, the order should be placed and the payment procedure should be completed before 1:00 pm on the same day, then same-day delivery can be arranged, but a surcharge is required.'
      },
      eg4: {
        title: '4. Additional Delivery Fees',
        content1:
          'Additional delivery fees will not be displayed on the invoice. It will be calculated on a per-order basis according to the actual situation, and need to be paid by the buyer directly in cash in a reimbursable manner to the delivery driver. If the buyer does not pay these fees, the driver will refuse the delivery and all losses incurred will be deducted from the paid fees. Please note that there is no refund on additional transportation fee.',
        content2:
          'Additional transportation fees include but not limited to the following items:',
        content3: '(i) Overtime Waiting Charge',
        content4:
          'There is no charge for the first 30 minutes of waiting time, and after that the charge is $200.00 per hour. Part of an hour will be charged as an hour.',
        content5:
          '(ii) Registration fee, parking lot fee, entry fee or any other miscellaneous fees',
        content6:
          '**If customer can provide the gate paper/card, please inform the delivery driver in advance to avoid the entry fee. The delivery team will contact customer before delivery. If there is a way to waive the related miscellaneous fees, please notify the delivery team in advance to arrange.'
      },
      title2: 'Pick-up Arrangements',
      eg5: {
        title: '1. Pick-up Instructions',
        content1:
          '1.1 After the order payment is confirmed, the customer will receive an e-mail with the pick-up note. Customer should pick up the products on the specified day and period. Please refer to Part 2 for information on pick-up time.',
        content2:
          '1.2 All pick-up arrangements are calculated per order, and only one-time pick-up is allowed.',
        content3:
          '1.3 Customer must present a valid Pick-up note and company seal when signing for pick-up. If the company seal does not match the name of Pick-up note company, and / or if the Pick-up note and / or company seal are not provided, the products will not be released. All fares, road / bridge tolls, entry fee, registration fee, waiting fees, etc., which are caused by failure to pick up the products, will not be recovered and / or refunded.',
        content4:
          '1.4 The price of the product does not include the expenses incurred from the product pick-up. Customers picking up product at the warehouse must pay miscellaneous charges. The incidental charges include entry fees and registration fees. Please refer to the fee arrangement for details.',
        content5:
          '',
        content6:
          '1.5 Rainstorm Warning Signal and Typhoon Signal Delivery Arrangements:',
        content7:
          '(i) Yellow, Red Rainstorm Warning Signal or Typhoon Signal No. 3 or below : Pick-up Arrangement is normally maintained, but there may be delays due to deterioration of road conditions.',
        content8:
          '(ii) Black Rainstorm Warning Signal, Typhoon Signal No. 8 or above, or vehicle suspension due to weather, severe flooding, road obstruction or closure: delivery service will be suspended, and the pick-up date and time needs to be re-arranged.',
        content9:
          '1.6 Upon receipt of product, we require your signature representing that responsibilities of purchased products have been transferred to you. If you have specified a recipient who is not you, then you accept that the signature by recipient is an evidence of pick-up and fulfillment by HKSTS.',
        content10:
          '1.7 We reserves the right to make any changes to the terms of the Pick-up Arrangements without prior notice and to retain the final decision on the transaction. In the event of any discrepancy or inconsistency between the English version and the Chinese version, the Chinese version shall prevail.'
      },
      eg6: {
        title: '2. Pick-up Time',
        content1:
          '2.1 Warehouse Address：No.53, Kam Po Road, San Tin, Yuen Long, New Territories (Wei Sheng warehouse) Aircargo Security Screening Centre Limited',
        content2: '2.2 Pick-up Time**: Monday to Saturday, 09:00-18:00',
        content3:
          '** Please note: The above is only the estimated time, the time for pick-up may be different due to unpredictable reasons. We have the right to make any changes to the pick-up arrangement without prior notice and reserves the right to make the final decision.'
      },
      title3: 'Fee Arrangement',
      eg7: {
        title:
          'The price of the product does not include the expenses incurred from the product pick-up. Customers picking up product at the warehouse must pay miscellaneous charges in cash. The incidental charges include entry fee and registration fee. Please refer to the fee arrangement for details.',
        content1: '(i) Fee items:',
        content2: {
          title1: 'Item',
          title2: 'Time Slot',
          title3: 'Remarks',
          type1: 'Entry Fee',
          type2: 'Registration Fee / Handling Fee',
          time1: 'From 09:00 to 17:59',
          time2: 'From 18:00 to 08:59',
          price1: '(Each truck) $150',
          price2: '(Each truck) $200',
          price3: '(Each truck) $100'
        },
        content3:
          '** Please note: The above is only a reference, all charges are subject to the warehouse.We reserves the right to make any changes to the above fee arrangement without prior notice and reserves the right of final decision.'
      }
    },
    exchange: {
      title: 'Exchange Policy',
      eg1: {
        title: '1. Exchange policy',
        content1: '"7-Day Shopping Guarantee"',
        content2:
          'We provide you with "7-Day shopping guarantee" (must fulfill the rules of "Exchange conditions"), products sold on the Website can be exchanged within 7 days from the receipt of the product (subject to the delivery receipt record). You can rest assured to shop here. Please contact us before making the exchange. Please note that all orders that have been confirmed for payment cannot be cancelled and no refund arrangements will be made.',
        content3:
          'We reserves the right to make any changes to the terms of the Exchange and Refund Policy without prior notice and to retain the final decision on the transaction. In the event of any discrepancy or inconsistency between the English version and the Chinese version, the Chinese version shall prevail.'
      },
      eg2: {
        title: '2. Exchange Conditions',
        content1:
          'The product to be exchanged must meet the following requirements, otherwise, the exchange application will not be accepted:',
        content2:
          '2.1 The product must be exchanged within 7 days after receipt.',
        content3:
          '2.2 Before using, the product is found to have any damage caused by non-human factors, quality problems, or loose or missing parts.',
        content4: '2.3 The product must be unused.',
        content5:
          '2.4 The product must be kept in good condition, intact and retain the original box and accessories.',
        content6: '2.5 Product must be purchased on the our Website.',
        content7:
          '2.6 In case of any dispute, Hong Kong Secure Transportation Systems Ltd. reserves the right of final decision and no objection can be raised.'
      },
      eg3: {
        title: '3. Exchange Instructions',
        content1:
          'Our services have always been professional and cautious, we will try our best to ensure the quality of all products and ensure that the products are correct before leaving the warehouse. If you find any damage caused by non-human factors, quality problems, or loose or missing parts after receiving the products, please follow the "Exchange Procedures" for replacement.',
        content2:
          '3.1 If you find the package of product is damaged, please check and confirm in front of the delivery person whether the Integrity and status of the product are good or not. Otherwise, please refuse to sign. If you have any questions, please email us at ',
        content3: ' to contact our customer service team.',
        content4:
          '3.2 After received the product, if you find the products having any damage caused by non-human factors, quality problems, or loose or missing parts and need to be returned to us. We will take responsibility and arrange replacement for you. We will then arrange the delivery again based on the delivery address of your order. This transportation fee will be borne by the manufacturer, but it does not include the additional transportation fees (e.g. overtime waiting charge).',
        content5:
          '3.3 We will not accept any other reasons of product replacement, except for those product fulfill the rules of "Exchange conditions".',
        content6:
          '3.4 All losses caused by unable to use the product during product replacement period shall be borne by the customer.'
      },
      eg4: {
        title: '4. Exchange Procedures',
        content1:
          'For exchanging the product, please follow the procedures below:',
        content2:
          '4.1 Customers are required to submit the exchanging request by email at ',
        content3:
          'within 7 days from the date of receipt. Please indicate the email with "Exchanging Request" and the order number.  In the email, please provide a screenshot of the order confirmation email, information on the replacement of the product, reasons for exchanging, relevant photo and contact information. If the information provided by the customer is partly missing, this will affect the time of exchange.',
        content4:
          '4.2 After receiving our confirmation email, we will contact you for the arrangement of returning the products.',
        content5:
          '4.3 After receiving the returned goods, we will confirm whether the products are in compliance with the corresponding requirements of the Exchange Conditions. We will then inform you the status of application as soon as possible and will arrange the appropriate exchanging method.',
        content6:
          '4.4 After confirming the returning method with you, you must complete the returning process within 7 days. Otherwise, you will be deemed to have waived the request and have no right to claim from the company.',
        content7:
          '4.5 When the above procedure is completed, we will arrange the delivery according to the delivery address of your order, and will be completed within 14 days. This transportation fee will be borne by the manufacturer, but it does not include the additional transportation fees (e.g. overtime waiting charge). If the product is out-of-stock, we must wait for the product arrived before arrange the delivery again, the time required may exceed 14 days, we will not be liable for any direct, indirect, special or consequential loss.'
      },
      eg5: {
        title: '5. Unsuccessful Delivery',
        content1:
          'Some Orders with incorrect address/missing information, unsuccessful delivery attempts, refusal or delay of consignee in receiving the order will cause the product returning.',
        content2:
          'The product of undeliverable orders will be returned to us. We can arrange the delivery of the returned order again, but customer must pay the transportation fee again. Please refer to the',
        content3: ' Delivery Arrangements ',
        content4:
          ' for details regarding transportation fees and other possible cost arrangements.',
        content5:
          'If the product cannot be able to deliver to customer at the end, all paid fee cannot be refunded.'
      }
    },
    payment: {
      title: 'Payment Methods',
      tips: 'Payment Procedures',
      eg1: {
        title:
          '1. All prices and charges on this Website are in Hong Kong Dollars (HK$).'
      },
      eg2: {
        title: '2. The payment methods can be chosen:',
        content1:
          '(i) FPS or Bank in deposit/transfer - bank counter, ATM, e-banking',
        content2: '(ii) Visa and Mastercard credit card payments processed by the Stripe online payment system',
        content3:
          '',
        content4: ''
      },
      eg3: {
        title: '3.',
        content1:
          '(i) If choosing to pay by FPS or bank in deposit/transfer, you will receive the invoice. Please note that this invoice does not means that we have accepted your order, you still have to complete the payment process and wait for the payment to be confirmed. Once the payment is confirmed, the order will be formally accepted and will be arranged for delivery.',
        content2:
          '(ii)  If choosing to pay by credit card, you will be turned to the Stripe online payment system to continue the payment process. Once the payment is confirmed, the order will be formally accepted and will be arranged for delivery.'
      },
      eg4: {
        title: '4. Details of various payment methods:',
        content1:
          '(i) FPS or Bank in deposit/transfer - bank counter, ATM, e-banking',
        content2:
          'For paying by bank in FPS or deposit/transfer, you will receive a payment notification email sent by the system. The email will include but not limited to the invoice, the payment method, the link for uploading the payment slip, etc. Please complete the payment within the specified day, then click the link and upload the payment slip (must be clear) to the system.The account information is as follows:',
        content3: 'The account information is as follows:',
        content4: 'Bank: ',
        content5: 'Hang Seng Bank',
        content6: 'Account Name: ',
        content7: 'HONG KONG SECURE TRANSPORTATION SYSTEMS LTD ',
        content8: 'Account number: ',
        content9: '395-666100-001',
        content10: '(ii) Cheque mailing',
        content11:
          'For paying by cheque mailing, please write the invoice number at the back of the cheque and mail to our Finance Department at “Room A, 18/F, TML Plaza, 3 Hoi Shing Road, Tsuen Wan, N.T., Hong Kong" on 3 working days before the payment due date. Do not mail cash. In general, it needs to take 3 to 5 working days for payment confirmation.',
        content12: 'Please note:',
        content13: 'i) Cheques must be crossed and payable to',
        content14: ' “HONG KONG SECURE TRANSPORTATION SYSTEMS LIMITED”',
        content15: 'ii)  Post-dated cheque will not be accepted.',
        content16:
          'In order to handle the payment confirmation process effectively, please inform us by email at ',
        content17:
          ' with the subject of “Cheque Sending” and the invoice number. Please include the following information in your email:',
        content18: 'Customer Number: STSxxxx',
        content19: 'Order number: XXXXXX',
        content20: 'Amount (HKD): $XXXX.XX',
        content21: 'Cheque number: XXXXXXXX',
        content22: '(ii) Credit Card (VISA & Mastercard)',
        content23:
          'In order to provide secure online shopping service, our online transaction is handled by Stripe online payment system. All credit card information, including credit card numbers, name of cardholder and passwords, will be encrypted before transmitted to the third-party online payment system for verification via the Internet. The data of customer cannot be read by the unauthorized third party when transmitting the information. In addition, the relevant information will not be stored on the online shop for record, truly providing customers with a safe and secure shopping environment.',
        content24: 'Payment procedure',
        content25:
          'For paying by credit card, you will be turned to the Stripe online payment system after selecting the payment method. Please enter the credit card information as instructed to complete the payment process. Upon completion, Stripe will notify us that the payment has been completed. Then you will receive the email, which include the receipt of order payment and details of the delivery.',
        content26: 'Please note:',
        content27:
          "(i) We will not be liable for any loss incurred as a result of payment through a third party's online payment gate. Before providing payment information to your online payment service provider, you must read all relevant terms and conditions.",
        content28:
          '(ii) For details of any credit card payment enquiries, please contact the issuing bank.',
        content29:
          '(iii)  In order to protect the customer, the payer must be the credit card cardholder.',
        content30:
          '(iv)  If customer changes the payment method due to personal reasons and needs to make a refund, the refund will be obtained through the original payment method. All additional fees (such as handling fees, service fees, etc.) incurred due to the refund must be paid by the customer, and the actual replacement time and related additional fees should depend on the card issuer, bank or electronic payment institution. We will not be liable for this behavior.'
      }
    }
  },
  ...enLocale
}
