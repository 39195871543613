<template>
  <div class="hksts" :class="`hksts_${$i18n.locale}`">
    <hk-header v-show="hasHeader">
      <p v-if="desktopLabel" v-html="$t(desktopLabel)" class="hk-header__label hk-desktop" />
      <p v-if="mobileLabel" v-html="$t(mobileLabel)" class="hk-header__label hk-mobile" />
    </hk-header>
    <router-view class="hk-main" />
    <hk-footer v-show="hasFooter" :options="footerOptions" />
    <hk-sidebar v-if="hasSidebar" :options="tabOptions" :cart-total="cartCount" />
    <el-dialog class="hk-alert" :visible.sync="showLoginAlert">
      <div>
        <p>{{ $t('login.loginFirst') }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="closeLoginDialog">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog class="hk-alert" :visible.sync="showReviewAlert">
      <div>
        <p>{{ $t('common.underReview') }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="closeReviewDialog">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HkHeader from '@/components/header'
import HkFooter from '@/components/footer'
import HkSidebar from '@/components/sidebar'
export default {
  name: 'HkLayout',
  components: {
    HkHeader,
    HkFooter,
    HkSidebar,
  },
  data() {
    this.getInitCartNumber()
    return {
      registration: null,
      showUpdateExists: false,
      refreshing: false,
      cartTotal: 0,
    }
  },
  watch: {
    cartTotal() {
      let num = this.$store.state.config.cartNum
      return num
    },
  },
  computed: {
    footerOptions() {
      return [
        { label: this.$t('footer.company'), to: 'aboutCompany' },
        { label: this.$t('footer.contact'), to: 'aboutContact' },
        { label: this.$t('footer.faqs'), to: 'aboutFaqs' },
        { label: this.$t('footer.conditions'), to: 'aboutConditions' },
        { label: this.$t('footer.privacy'), to: 'aboutPrivacy' },
        { label: this.$t('footer.delivery'), to: 'aboutDelivery' },
        { label: this.$t('footer.exchange'), to: 'aboutExchange' },
        { label: this.$t('footer.payment'), to: 'aboutPayment' },
      ]
    },
    tabOptions() {
      return [
        { label: this.$t('tab.home'), icon: 'icon-home', to: 'home' },
        { label: this.$t('tab.favorite'), icon: 'icon-like', to: 'favorite' },
        { label: this.$t('tab.cart'), icon: 'icon-cart', to: 'cart' },
        { label: this.$t('tab.account'), icon: 'icon-mine', to: 'account' },
      ]
    },
    overflowScroll() {
      return this.$route.meta.scroll
    },
    hasSidebar() {
      return this.$route.meta.sidebar !== false
    },
    hasHeader() {
      return this.$route.meta.header !== false
    },
    hasFooter() {
      return this.$route.meta.footer !== false
    },
    desktopLabel() {
      return this.$route.meta.desktopLabel || ''
    },
    mobileLabel() {
      return this.$route.meta.mobileLabel || ''
    },
    cartCount: function () {
      return this.$store.state.config.cartNum
    },
    showLoginAlert: function () {
      return this.$store.state.config.loginAlert
    },
    showReviewAlert: function () {
      return this.$store.state.config.reviewAlert
    },
  },
  watch: {
    overflowScroll: {
      handler(val) {
        document.body.style.overflowY = val ? 'scroll' : 'auto'
      },
      immediate: true,
    },
  },
  created() {
    this.initPwa()
  },
  methods: {
    initPwa() {
      document.addEventListener('swUpdated', this.showUpdateDialog, {
        once: true,
      })
      navigator.serviceWorker &&
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (this.refreshing) return
          this.refreshing = true
          window.location.reload()
        })
    },
    refreshApp() {
      this.showUpdateExists = false
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
    },
    showUpdateDialog(e) {
      this.registration = e.detail
      this.showUpdateExists = true
    },
    getInitCartNumber() {
      let self = this
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/productNum'
      this.axios
        .get(apiCall, header)
        .then(function (response) {
          let num = response.data.data.product_num
          self.$store.commit('updateCartNum', num)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    closeLoginDialog() {
      this.$store.commit('closeLoginDialog')
    },
    closeReviewDialog() {
      this.$store.commit('closeReviewDialog')
    },
  },
}
</script>

<style lang="scss">
.hk-header__label {
  margin: 70px 30px;
  margin-bottom: 30px;
  font-weight: 500;
  line-height: 80px;
  color: $blockColor;
  @include label($headerSize);

  b {
    font-weight: bold;
  }
}

@include mobile {
  .hk-header__label {
    margin: 80px 35px 0 35px;
  }
}
</style>
<style>
.hk-alert {
  height: 600px;
}
.hk-alert .hk-alert__button {
  padding-top: 30px;
  padding-bottom: 30px;
}
.hk-alert .el-dialog__body {
  padding: 1rem !important;
}
.el-icon-close {
  display: none !important;
}
</style>
