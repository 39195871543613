import Vue from 'vue'
import Router from 'vue-router'
import HkLayout from '@/layout'
import rootRouter from './root'
import homeRouter from './home'
import aboutRouter from './about'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: [{
    path: '*',
    redirect: '/home'
  }, {
    path: '/',
    redirect: '/home'
  }, ...[
    rootRouter,
    homeRouter,
    aboutRouter
  ].map(item => ({
    path: item.base,
    component: HkLayout,
    children: item.routes
  }))]
})

router.beforeEach(async (to, from, next) => {
  next()
})

export default router
