<template>
  <el-input
    class="hk-search"
    :placeholder="$t('common.search')"
    v-model="search"
  >
    <i slot="prefix" class="iconfont icon-search" />
  </el-input>
</template>

<script>
export default {
  name: 'HkSearch',
  model: {
    prop: 'value',
    event: 'update:value'
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      search: ''
    }
  },
  watch: {
    value (val) {
      this.search = val
    },
    search (val) {
      val !== this.value && this.$emit('update:value', val) && this.getSearchResult(val);
    },
  },
  methods: {
    getSearchResult (val) {
      let self = this;
      let rootApi = self.$store.state.config.apiRoot;
      let keyword = val;
      let num = 10;
      let page = 1;
      let apiCall = rootApi + '/api/product/search?lang=' + self.$store.state.config.language + 
        '&name=' + keyword + 
        '&num=' + num +
        '&page=' + page;
      this.axios.get(apiCall)
      .then(function (response) {
        let search = {
          result: response.data.data.list,
          keyword: keyword
        };
        self.$store.commit('getSearch', search);
      })
      .catch(function (error) {
        console.log(error);
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.hk-search.el-input::v-deep {
  width: 250px;
  height: 42px;
  margin: 0 30px 0 60px;
  @include placeholder($subTitleColor);

  .iconfont {
    margin: 0 10px;
    font-size: $titleSize;
    line-height: 44px;
    color: $subTitleColor;
  }

  .el-input__inner {
    height: 100%;
    padding-left: 45px;
    font-size: $textSize;
    line-height: 40px;
    color: $subTitleColor;
    background-color: $subMainColor;
    border: none;
    border-radius: 50px;
  }

  @include mobile {
    display: block;
    width: auto;
    height: 70px;
    margin: 50px 30px;

    &.hk-search_no-slot {
      margin-top: 0;
    }

    .iconfont {
      margin: 0 20px;
      font-size: 32px;
      line-height: 70px;
    }

    .el-input__inner {
      padding-left: 80px;
      font-size: 30px;
      line-height: 70px;
      border-radius: 35px;
    }
  }
}
</style>
