export default {
  base: '/',
  routes: [{
    path: 'home',
    name: 'home',
    component: () => import('@/pages/home'),
    meta: {
      auth: false,
      desktopLabel: 'home.desktop',
      mobileLabel: 'home.mobile'
    }
  }, {
    path: 'seal',
    name: 'seal',
    component: () => import('@/pages/home/seal'),
    meta: {
      auth: false,
      desktopLabel: 'home.desktop',
      mobileLabel: 'home.mobile'
    }
  }, {
    path: 'account',
    name: 'account',
    component: () => import('@/pages/account'),
    meta: {
      sidebar: false,
      scroll: true
    }
  }, {
    path: 'detail',
    name: 'detail',
    component: () => import('@/pages/home/detail')
  }, {
    path: 'discount',
    name: 'discount',
    component: () => import('@/pages/home/discount')
  }, {
    path: 'favorite',
    name: 'favorite',
    component: () => import('@/pages/home/favorite')
  }, {
    path: 'search',
    name: 'search',
    component: () => import('@/pages/home/search')
  }, {
    path: 'cart',
    name: 'cart',
    component: () => import('@/pages/cart')
  }, {
    path: 'payment',
    name: 'payment',
    component: () => import('@/pages/account/payment'),
    meta: {
      sidebar: false
    }
  }]
}
